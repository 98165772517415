import {fetch} from '@infra/api/fetch';

const checkEmail = async (email: string): Promise<void> => {
  const response = await fetch('/api/user-exists', {
    headers: {Accept: 'application/json', 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest'},
    method: 'POST',
    body: JSON.stringify({email}),
  });

  if (response.status > 400) throw {code: response.status};

  return;
};

export const ApiCheckEmailRepository = {
  checkEmail,
};
