import React from 'react';

import {
  // Helpers
  ALIGN,
  // Components
  Text,
  View,
} from '@lookiero/aurora';

export const Copyright = () => (
  <>
    {Array.from({length: 15}).map((_, i) => (
      <View key={i} testID="fb-space">
        <Text>&zwnj;</Text>
      </View>
    ))}
    <Text align={ALIGN.CENTER} as="p" detail>
      {`© ${new Date().getFullYear()} LOOKIERO`}
    </Text>
  </>
);
