import gql from 'graphql-tag';

import {ACCOUNT_FIELDS} from '@infra/api/graphQL/domain/model/Account/AccountFields';

const PICKUP_POINTS_ALL_FIELDS = gql`
  query pickupPointsAllFields($postCode: String!, $country: String!) {
    pickupsByPostCode(postCode: $postCode, country: $country) {
      ${ACCOUNT_FIELDS.pickupAddressFields}
      ${ACCOUNT_FIELDS.deliveryFields}
      ${ACCOUNT_FIELDS.openingDays}
    }
  }
`;

export {PICKUP_POINTS_ALL_FIELDS};
