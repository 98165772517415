import React, {FC, useCallback, useEffect} from 'react';
import {Portal, useDevice, useKeyboard} from '@lookiero/aurora';

import {eventManager} from '@application/Event';
import {useApp} from '@app';
import {useWebview} from '@app/components/Webview';
import {PageEventView} from '@domain/model/Event/domain/model/UiEvents';
import {UiEvent} from '@domain/model/Event';

import {IsolatedPanel} from './IsolatedPanel';
import {useMountablePortal} from '@components/hooks';
import {useMenuLinks} from '@modules/menu';

export const Panel: FC = () => {
  const {isPanelVisible: isVisible} = useApp();

  const {isMountedAndVisible, showComponent: showPanel} = useMountablePortal({isVisible});

  return isMountedAndVisible ? (
    <Portal id="panel">
      <PanelWithoutPortal isVisible={showPanel} />
    </Portal>
  ) : null;
};

Panel.displayName = 'Panel';

const PanelWithoutPortal: FC<{isVisible: boolean}> = ({isVisible}) => {
  const {authorization, togglePanelVisibility} = useApp();
  const {platform} = useDevice();
  const {openLink} = useWebview();
  const {isKeyboardOpen, closeKeyboard} = useKeyboard();

  const {links} = useMenuLinks();

  useEffect(() => {
    if (platform.native && isVisible && isKeyboardOpen) {
      closeKeyboard();
    }
  }, [closeKeyboard, isKeyboardOpen, isVisible, platform.native]);

  useEffect(() => {
    if (isVisible) {
      eventManager.emit(UiEvent.PAGE_LOAD, {view: PageEventView.OPEN_MENU});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnPanelClose = useCallback(() => togglePanelVisibility(false), [togglePanelVisibility]);

  return (
    <IsolatedPanel
      visible={isVisible}
      authorization={authorization}
      links={links}
      onClose={handleOnPanelClose}
      openLink={openLink}
    />
  );
};

PanelWithoutPortal.displayName = 'PanelWithoutPortal';
