import eventEmitter from 'tiny-emitter/instance';

import {DomainEvent} from 'typings/domainEvent';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const emit = (event: DomainEvent, ...args: any): void => {
  eventEmitter.emit(event, ...args);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const subscribe = (event: DomainEvent, callback: (...args: any) => void): void => {
  eventEmitter.on(event, callback);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const unsubscribe = (event: DomainEvent, callback: (...args: any) => void): void => {
  eventEmitter.off(event, callback);
};

export const ApiEventManager = {
  emit,
  subscribe,
  unsubscribe,
};
