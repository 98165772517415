import StyleSheet from 'react-native-extended-stylesheet';

export const style = StyleSheet.create({
  gallery: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});
