import {Dimensions, Platform} from 'react-native';
import React, {FC, ReactNode} from 'react';
import {Aurora as AuroraWrapper, DISPLAY, FLEX_DIRECTION} from '@lookiero/aurora';

import {isTinyScreen} from './helpers';
import {ThemeTiny} from './themes';

const screen = Dimensions.get('window');
const isNative = Platform.OS === 'ios' || Platform.OS === 'android';

interface AuroraProps {
  readonly children: ReactNode;
}

const Aurora: FC<AuroraProps> = ({children}) => (
  <AuroraWrapper
    {...(isNative ? {display: DISPLAY.FLEX, flexDirection: FLEX_DIRECTION.COLUMN, wide: true} : {})}
    theme={{
      ...(isTinyScreen(screen) ? ThemeTiny : undefined),
    }}
  >
    {children}
  </AuroraWrapper>
);

export {Aurora};
