import {LocationApplication} from '@application/Site';
import {SiteDomain} from '@domain/model/Site/Site';
import {getPaymentInstrument as getPaymentInstrumentPayments, PaymentInstrument} from '@lookiero/payments-front';
import {AuthApplication} from '@application/Auth';
import {sendLogMessage} from '@infra/domain/model/ServerLog/ServerLogRepository';

export interface UserDataFields {
  readonly id?: string;
  readonly segment?: string;
}

const EMPTY_USER_DATA: UserDataFields = {id: undefined, segment: undefined};
let userData: UserDataFields = {};

export const initUserData = (initialData: UserDataFields): void => {
  userData = initialData;
};

const awaitUserData = (timeToWait: number): Promise<UserDataFields> =>
  new Promise((resolve, reject) =>
    setTimeout(() => {
      if (hasUserData()) resolve(userData);
      else reject();
    }, timeToWait),
  );

const MAX_TIME_TO_WAIT = 5000;
const hasValue = (v: unknown): boolean => v !== undefined && typeof v !== 'undefined';
const hasUserData = (): boolean =>
  Object.values(userData).filter(hasValue).length === Object.keys(userData).length && Object.keys(userData).length > 0;

export const getUserData = async (): Promise<UserDataFields> => {
  if (!(await isLogged())) {
    return EMPTY_USER_DATA;
  }
  let totalWaitedTime = 0;
  let timeToWait = 50;
  if (!hasUserData()) {
    while (totalWaitedTime <= MAX_TIME_TO_WAIT) {
      totalWaitedTime += timeToWait;
      try {
        return await awaitUserData(timeToWait);
      } catch (e) {
        timeToWait *= 1.25;
      }
    }
    void sendLogMessage(`Unable to get UserData for tracking totalWaitedTime=${totalWaitedTime}`);
    return EMPTY_USER_DATA;
  }

  return userData;
};

export const cleanUserData = (): void => {
  userData = {};
};

export const getStore = async (): Promise<SiteDomain> => {
  return await LocationApplication.getStore();
};
export const getPaymentInstrument = async (): Promise<PaymentInstrument | null> => {
  return await getPaymentInstrumentPayments();
};
export const isLogged = async (): Promise<boolean> => {
  return await AuthApplication.isLoggedIn();
};
