import {useEffect} from 'react';
import {useLocation} from 'react-router-native';

import {PromoCodeApplication} from '@application/PromoCode';

const PROMO_PARAM = 'promo';

export const usePromoUrlSetup = (): void => {
  const {search} = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(search);
    PromoCodeApplication.setUrlPromoCode(params.get(PROMO_PARAM));
  }, [search]);
};
