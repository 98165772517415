import React from 'react';
import {useIntl} from 'react-intl';

import {useSettings} from '@components/_context/SettingsContext';

import {UAFHead} from '../_bridges/uafHead';
import {TEXT} from './HeadManager.definition';

const HeadManager = () => {
  const {formatMessage} = useIntl();
  const {languages} = useSettings();
  const lang = (languages && languages[0] && languages[0].substring(0, 2)) || ''; // ISO 639-1 Language Codes
  return (
    <UAFHead htmlAttributes={{lang}}>
      <title>{formatMessage(TEXT.TITLE)}</title>
    </UAFHead>
  );
};

export {HeadManager};
