import {GetVersionStatusCommand} from '@domain/model/App/GetVersionStatusCommand';
import {ServerVersionStatus, VersionStatus} from '@domain/model/App/VersionStatus';
import {getAuthHeader} from '@infra/api/authManager/authManager';
import {fetch} from '@infra/api/fetch';
import {DEFAULT_HEADERS} from '../_utils/defaultHeaders';

const getVersionStatus = async ({platform, version}: GetVersionStatusCommand): Promise<VersionStatus> => {
  const authHeader = await getAuthHeader();
  const url = `/api/v1/appVersioningCheck?version=${version}&platform=${platform}`;
  try {
    const result = await fetch(url, {
      method: 'GET',
      headers: {
        ...authHeader,
        ...DEFAULT_HEADERS,
      },
    });

    const response: ServerVersionStatus = await result.json();
    const {recommend_upgrade: recommend, force_upgrade: force} = response;

    if ((recommend && force) || (!recommend && !force)) {
      return Promise.reject();
    }

    return Promise.resolve(force ? VersionStatus.FORCE : VersionStatus.RECOMMEND);
  } catch (error) {
    return Promise.reject(error);
  }
};

const ApiAppRepository = {
  getVersionStatus,
};

export {ApiAppRepository};
