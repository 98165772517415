import gql from 'graphql-tag';

const CALENDAR_DATES = gql`
  query calendar($country: String!, $userId: String!) {
    calendar(country: $country, userId: $userId) {
      unavailableDates
      minDate
      maxDate
    }
  }
`;

export {CALENDAR_DATES};
