import {Notification, useStack} from '@lookiero/aurora';
import {iOSInAppBrowserVideoAd} from 'ui/helpers';

import {style} from './ToastContext.styles';
import {Component, FunctionComponent, ReactNode} from 'react';
import {NotificationProperties} from '@lookiero/aurora/build/components/molecules/Notification/Notification';

const TOAST_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
};

const getNotificationDefaults = (): Partial<NotificationProperties> => ({
  motion: 'top',
  style: style.container,
  timeoutClose: 10000,
});

const getInAppNotificationDefaults = (): Partial<NotificationProperties> => ({
  ...getNotificationDefaults(),
  style: [style.container, style.inAppContainer],
});

const getNotificationProps = (): Partial<NotificationProperties> =>
  iOSInAppBrowserVideoAd ? getInAppNotificationDefaults() : getNotificationDefaults();
const generateUid = (): string => new Date().getTime().toString();

export interface Toast {
  show: (Component: Component | FunctionComponent, props: NotificationProperties) => void;
  showError: (props: ShowFunctionProps) => void;
  showSuccess: (props: ShowFunctionProps) => void;
  showWarning: (props: ShowFunctionProps) => void;
  showInformation: (props: ShowFunctionProps) => void;
  showPromo: (props: ShowFunctionProps) => void;
  hide: (id: string) => void;
}

export type ShowFunctionProps = {message: ReactNode; settings?: Partial<NotificationProperties>};

const useToast = (uid?: string): Toast => {
  const Stack = useStack();

  const getUid = (): string => uid || generateUid();

  return {
    show: (Component: Component | FunctionComponent, props: NotificationProperties) => {
      Stack.show(getUid(), Component, props);
    },
    showError: ({message, settings = {}}: ShowFunctionProps) => {
      Stack.critical(getUid(), Notification, {...getNotificationProps(), ...settings, text: message});
    },
    showSuccess: ({message, settings = {}}: ShowFunctionProps) => {
      Stack.success(getUid(), Notification, {...getNotificationProps(), ...settings, text: message});
    },
    showWarning: ({message, settings = {}}: ShowFunctionProps) => {
      Stack.warning(getUid(), Notification, {...getNotificationProps(), ...settings, text: message});
    },
    showInformation: ({message, settings = {}}: ShowFunctionProps) => {
      Stack.neutral(getUid(), Notification, {...getNotificationProps(), ...settings, text: message});
    },
    showPromo: ({message, settings = {}}: ShowFunctionProps) => {
      Stack.promo(getUid(), Notification, {...getNotificationProps(), ...settings, text: message});
    },
    hide: (id: string) => {
      Stack.hide(id);
    },
  };
};

export {getNotificationDefaults, useToast, TOAST_TYPES, generateUid};
