import React, {FC} from 'react';
import {
  FLEX_DIRECTION,
  //Components
  Text,
  View,
  Button,
  SPACE,
} from '@lookiero/aurora';

import {style} from '../ModalReturnInstructions.styles';
import {TEXT} from '../ModalReturnInstructions.definition';
import {useIntl} from 'react-intl';
import {openExternalLink} from '@router/helpers';

interface ModalReturnInstructionsProps {
  links: {[k: string]: string | undefined};
  orderReference?: string;
}

export const ModalReturnInstructionsEVRI: FC<ModalReturnInstructionsProps> = ({links, orderReference}) => {
  const {formatMessage} = useIntl();
  return (
    <>
      <View marginBottom={SPACE.SPACE_8} style={style.steps}>
        <View flexDirection={FLEX_DIRECTION.ROW} key={0} marginBottom={SPACE.SPACE_2}>
          <View style={style.circle}>
            <Text detail>1</Text>
          </View>
          <Text>{formatMessage(orderReference !== undefined ? TEXT.STEPS.ONE : TEXT.SHOP_STEPS.ONE)}</Text>
        </View>
        <View flexDirection={FLEX_DIRECTION.ROW} key={1} marginBottom={SPACE.SPACE_6}>
          <View style={style.circle}>
            <Text detail>2</Text>
          </View>
          <Text>{formatMessage(orderReference !== undefined ? TEXT.STEPS.TWO : TEXT.SHOP_STEPS.TWO)}</Text>
        </View>
        {orderReference !== undefined ? (
          <View flexDirection={FLEX_DIRECTION.ROW} key={2}>
            <View style={style.circle}>
              <Text detail>3</Text>
            </View>
            <Text>{formatMessage(TEXT.STEPS.THREE)}</Text>
          </View>
        ) : null}
      </View>
      <Button
        onPress={() => openExternalLink({url: links.searchPickupPoint as string})}
        testID={`find-drop-off-button`}
      >
        {formatMessage(TEXT.RETURN_BUTTON_GB)}
      </Button>
    </>
  );
};
