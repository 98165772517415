import React from 'react';
import {useIntl} from 'react-intl';
import {Text, TextProperties} from '@lookiero/aurora';

import {useSettings} from '@components/_context/SettingsContext';
import {amountToAbsoluteAndSign, getOps} from './Currency.controller';

import {formatCurrency} from '../formatCurrency';

interface CurrencyProps extends TextProperties {
  amount: number;
  plus?: boolean;
  decimals?: boolean;
}

const Currency: React.FC<CurrencyProps> = ({amount, plus = false, decimals = false, ...rest}) => {
  const {formatNumber} = useIntl();
  const {currency} = useSettings();
  const {sign, absoluteAmount} = amountToAbsoluteAndSign(amount, plus);

  return (
    <Text {...rest}>
      {sign}
      {formatCurrency(formatNumber(absoluteAmount, getOps(absoluteAmount, decimals)), {currency})}
    </Text>
  );
};

export {Currency};
