import {SiteApplication, LocationApplication} from '@application/Site';

import {sendException} from '../../errors/errorLogger';
import {ConfigurationApplication} from '@application/Config';

const CACHE_ERRORS = {};

const formatLocale = ({language = 'es', country = 'ES'} = {}) => `${language.toLowerCase()}-${country.toUpperCase()}`;

const getLocale = async () => LocationApplication.getLocale();

const getMessages = async (locale = {}) => {
  const messages = await SiteApplication.getMessagesByLocale(locale);

  return messages;
};

const handleError = error => {
  const environment = ConfigurationApplication.retrieve().environment;
  const cachedError = error.toString();

  // TODO environment is never development, it is DEVELOPMENT
  if (environment === 'development' && !CACHE_ERRORS[cachedError]) {
    CACHE_ERRORS[cachedError] = true;
    sendException(error);
  }
};

export {formatLocale, getLocale, getMessages, handleError};
