import {User} from '@domain/model/User/User';
import {UserRepository} from '@domain/model/User/UserRepository';

const GetUserOverallInfo =
  (userRepository: UserRepository): (() => Promise<User | null>) =>
  (): Promise<User | null> => {
    return userRepository.getUserOverallInfo();
  };

export {GetUserOverallInfo};
