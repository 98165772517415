import {ApiBillingAddressRepository} from '@infra/domain/model/Account/BillingAddress/ApiBillingAddressRepository';
import {ApiCheckEmailRepository} from '@infra/domain/model/Account/CheckEmail/ApiCheckEmailRepository';
import {ApiMyLookieroRepository} from '@infra/domain/model/Account/MyLookiero/ApiMyLookieroRepository';
import {ApiPickupPointRepository} from '@infra/domain/model/Account/PickupPoint/ApiPickupPointRepository';
import {ApiPromoCodeRepository} from '@infra/domain/model/Account/PromoCode/ApiPromoCodeRepository';
import {ApiResetPasswordRepository} from '@infra/domain/model/Account/ResetPassword/ApiResetPasswordRepository';
import {ApiShippingAddressRepository} from '@infra/domain/model/Account//ShippingAddress/ApiShippingAddressRepository';
import {ApiSubscriptionRepository} from '@infra/domain/model/Account/Subscription/ApiSubscriptionRepository';
import {ApiUpdateEmailRepository} from '@infra/domain/model/Account/UpdateEmail/ApiUpdateEmailRepository';
import {ApiDeleteAccountRepository} from '@infra/domain/model/Account/DeleteAccount/ApiDeleteAccountRepository';

const {checkEmail} = ApiCheckEmailRepository;
const {getBillingAddress, setBillingAddress} = ApiBillingAddressRepository;
const {getPickupPoints} = ApiPickupPointRepository;
const {redeemPromoCode} = ApiPromoCodeRepository;
const {resetPassword} = ApiResetPasswordRepository;
const {setShippingAddress} = ApiShippingAddressRepository;
const {setSubscription, skipMonth} = ApiSubscriptionRepository;
const {updateEmail} = ApiUpdateEmailRepository;
const {updateNextOrderDate, getCalendarDates} = ApiMyLookieroRepository;
const {deleteAccount} = ApiDeleteAccountRepository;

export const ApiAccountRepository = {
  checkEmail,
  getBillingAddress,
  getCalendarDates,
  getPickupPoints,
  redeemPromoCode,
  resetPassword,
  setBillingAddress,
  setShippingAddress,
  setSubscription,
  skipMonth,
  updateEmail,
  updateNextOrderDate,
  deleteAccount,
};
