import {useEffect} from 'react';

import {useEvent} from '@lookiero/event';
import {PaymentsError} from '@lookiero/payments-front';
import {getClient, sendException} from '../../../errors/errorLogger';
import {isStySpSentryProject} from '../../../errors/isStySpSentryProject';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const errorParser = (error: any): any => {
  const {error: nestedError} = error;

  if (nestedError) {
    if (nestedError instanceof PaymentsError) return nestedError;
    return new PaymentsError(nestedError);
  }

  return error;
};

export const useSentryForPayments = (): void => {
  const {subscribe, unsubscribe} = useEvent();

  const notifySentry = (error: unknown): void => {
    const dsn = getClient()?.getDsn();

    if (dsn?.projectId && isStySpSentryProject(dsn?.projectId)) {
      return;
    }

    sendException(errorParser(error));
  };

  useEffect(() => {
    subscribe({domain: 'PAYMENTS', event: 'MONITORING.ERROR'}, notifySentry);

    return () => unsubscribe({domain: 'PAYMENTS', event: 'MONITORING.ERROR'}, notifySentry);
  }, [subscribe, unsubscribe]);
};
