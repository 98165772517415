import {Shipment} from '@domain/model/Shipment/Shipment';

import {request} from '@lookiero/data-sources';
import {getAuthHeader} from '@infra/api/authManager/authManager';
import {proxy} from '@infra/api/fetch/proxy';

import {ShipmentRepository} from '@domain/model/Shipment/ShipmentRepository';

const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

const getShipmentByOrderReference = async (orderReference: string): Promise<Shipment> => {
  const url = `/api/shipments/orderReference=${orderReference}`;
  const headers = {...DEFAULT_HEADERS, ...(await getAuthHeader())};
  const requestConfig = {
    endpoint: proxy(url),
    method: 'GET',
    headers,
  };

  return request(requestConfig);
};

const getShipmentByServiceId = async (serviceId: string): Promise<Shipment> => {
  const url = `/api/shipments/serviceId=${serviceId}`;
  const requestConfig = {
    endpoint: proxy(url),
    method: 'GET',
    DEFAULT_HEADERS,
  };

  return request(requestConfig);
};

const ApiShipmentRepository: ShipmentRepository = {
  getShipmentByOrderReference: getShipmentByOrderReference,
  getShipmentByServiceId: getShipmentByServiceId,
};

export {ApiShipmentRepository};
