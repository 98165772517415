import {PromoCode} from '@domain/model/Account/PromoCode/PromoCode';
import {RedeemPromoCodeCommand} from '@domain/model/Account/PromoCode/PromoCodeRepository';
import {getAuthHeader} from '@infra/api/authManager/authManager';

import {DEFAULT_HEADERS} from '../../_utils/defaultHeaders';
import {fetch} from '@infra/api/fetch';

const BOX_REQUEST_URL = '/api/v1/redeemPromocode';

const redeemPromoCode = async ({promoCode, device, service}: RedeemPromoCodeCommand): Promise<PromoCode> => {
  const authHeader = await getAuthHeader();
  try {
    const result = await fetch(BOX_REQUEST_URL, {
      method: 'POST',
      headers: {
        ...authHeader,
        ...DEFAULT_HEADERS,
      },
      body: JSON.stringify({
        promocode: promoCode,
        device,
        service,
      }),
    });
    const response = await result.json();
    if (!result.ok) {
      return Promise.reject(response);
    }
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const ApiPromoCodeRepository = {
  redeemPromoCode,
};
