import React from 'react';
import {getClientId} from '@views/_components/Google/helpers/getClientId';
import {GoogleOAuthProvider} from '@react-oauth/google';

export const GoogleSignInProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const clientId = getClientId();
  if (clientId) {
    return <GoogleOAuthProvider clientId={clientId}>{children}</GoogleOAuthProvider>;
  }
  return <>{children}</>;
};
