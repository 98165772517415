export enum NavigationEvent {
  QUIZ_STARTED = 'navigation.quiz-started',
  QUIZ_FINISHED = 'navigation.quiz-finished',
  QUIZ_STEP_SUBMIT = 'navigation.quiz-step-submit',
  ONBOARDING_STARTED = 'navigation.onBoarding-started',
  ONBOARDING_FINISHED = 'navigation.onBoarding-finished',
  RANGE_PRICES = 'navigation.range-prices',
  IS_MOTHER = 'navigation.is-mother',
  REFERRAL_CODE_SHARE = 'navigation.referral-code-share',
  DOWNLOAD_APP_BUTTONS = 'downloadAppButton',
  VIEW_POST_ORDER_MODAL = 'myLKPostOrderModalView',
  CLOSE_POST_ORDER_MODAL = 'myLKPostOrderModalClose',
  MANAGE_PLAN = 'navigation.managePlan',
}
