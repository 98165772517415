import React from 'react';

import {useRefreshSession} from '@app/helpers/useRefreshSession';

const AppBootstrap: React.FC = () => {
  useRefreshSession();
  return <></>;
};

export {AppBootstrap};
