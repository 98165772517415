import {useLayoutEffect} from 'react';
import {useApp} from '@app/App.context';

export const useHeader = (headerOptions, unmountHeaderOptions = undefined) => {
  const {setHeader} = useApp();

  useLayoutEffect(() => {
    setHeader(headerOptions);
    return () => setHeader(unmountHeaderOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerOptions]);
};
