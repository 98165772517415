import {DIRECT_BUY_EVENT} from '@lookiero/direct-buy-front';

import React, {FC, createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {fetchEnabledOfferings, isPrimaryRoute as isShopPrimaryRoute} from '@lookiero/direct-buy-front';
import {useApp} from '@app';
import {getAuth} from '@infra/api/authManager/authManager';
import {useLocation} from 'react-router-native';
import {Path} from '../../../modules/routing/routes.definition';
import {eventManager} from '@application/Event';
import {CheckoutEvent} from '@domain/model/Event';
import debounce from 'lodash.debounce';
import {useFeatureFlags} from './FeatureFlagContext';
import {DomainEvent} from '../../../typings/domainEvent';

// TODO: import this type from DBF once it exports types
type EnabledOffferings = {
  completeYourLook: boolean;
  categories: boolean;
  sales: boolean;
};

enum ShopRouteTypes {
  OUTTER,
  PRIMARY,
  SECONDARY,
}

interface ShopContextApi {
  readonly canAccess: boolean;
  readonly enabledAndToggledOfferings: EnabledOffferings | undefined;
  readonly contextLoading: boolean;
  readonly shopRouteType: ShopRouteTypes;
}

interface ShopProviderProps {
  readonly children: React.ReactNode;
}

const ShopContext = createContext<ShopContextApi>({} as ShopContextApi);

const ShopProvider: FC<ShopProviderProps> = ({children}) => {
  const featureFlags = useFeatureFlags() || {};

  const {authorization} = useApp();
  const [authToken, setAuthToken] = useState<string>();
  const [enabledAndToggledOfferings, setEnabledAndToggledOfferings] = useState<EnabledOffferings>();

  const location = useLocation();

  const shopRouteType: ShopRouteTypes = useMemo(() => {
    if (location.pathname.includes(Path.SHOP_HOME) === false) {
      return ShopRouteTypes.OUTTER;
    }

    return isShopPrimaryRoute(location.pathname) ? ShopRouteTypes.PRIMARY : ShopRouteTypes.SECONDARY;
  }, [location.pathname]);

  useEffect(() => {
    (async () => {
      const token = await getAuth();
      if (token) {
        setAuthToken(`Bearer ${token}`);
      }
    })();
  }, [authorization]);

  const fetchEnabledOfferingsState = useCallback(
    async (afterCheckout: boolean) => {
      if (authToken) {
        const fetchedEnabledOfferings = await fetchEnabledOfferings(authToken);

        setEnabledAndToggledOfferings(fetchedEnabledOfferings);

        if (afterCheckout) {
          eventManager.emit(DIRECT_BUY_EVENT.AFTER_CHECKOUT_ACCESS_GRANTED as DomainEvent, {
            enabledOfferings: fetchedEnabledOfferings,
          });
        }
      }
    },
    [authToken],
  );

  useEffect(() => {
    (async () => {
      await fetchEnabledOfferingsState(false);
    })();
  }, [authToken, featureFlags, fetchEnabledOfferingsState]);

  const debouncedRefetchAccesesAfterCheckout = useMemo(
    () => debounce(() => fetchEnabledOfferingsState(true), 100),
    [fetchEnabledOfferingsState],
  );

  useEffect(() => {
    eventManager.subscribe(CheckoutEvent.CHECKOUT_SUBMITTED, debouncedRefetchAccesesAfterCheckout);
    return () => eventManager.unsubscribe(CheckoutEvent.CHECKOUT_SUBMITTED, debouncedRefetchAccesesAfterCheckout);
  });

  const directBuyFeatureFlag = featureFlags.DIRECT_BUY || false;

  const canAccess = Boolean(
    directBuyFeatureFlag && enabledAndToggledOfferings && Object.values(enabledAndToggledOfferings).some(Boolean),
  );
  const contextLoading =
    !authToken || featureFlags.DIRECT_BUY === undefined || enabledAndToggledOfferings === undefined;

  const value = useMemo(
    () => ({
      canAccess,
      enabledAndToggledOfferings,
      contextLoading,
      shopRouteType,
    }),
    [canAccess, contextLoading, shopRouteType, enabledAndToggledOfferings],
  );

  return <ShopContext.Provider value={value}>{children}</ShopContext.Provider>;
};

const useShop = (): ShopContextApi => useContext(ShopContext);

export {ShopRouteTypes, ShopContext, ShopProvider, useShop};
