import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {IntlProvider} from 'react-intl';
import {LocaleProvider} from '@lookiero/locale';

import {formatLocale, getLocale, getMessages, handleError} from './MessagesProvider.controller';

const value = {forceUpdate: () => undefined, updateLocale: {}};
const useUpdateLocale = () => value;

const LOCALE_DICTIONARIES = ['user-area-front', 'payments-front'];

const MessagesProvider = ({children}) => {
  const [messages, setMessages] = useState(null);
  const [locale, setLocale] = useState();

  useEffect(() => {
    const fetchMessages = async () => {
      const locale = await getLocale();
      setLocale(locale);
      setMessages(await getMessages(locale));
    };

    if (!messages) fetchMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    messages && (
      <LocaleProvider locale={`${locale.language}-${locale.country}`} dictionaries={LOCALE_DICTIONARIES}>
        <IntlProvider locale={formatLocale(locale)} messages={messages} onError={handleError}>
          {children}
        </IntlProvider>
      </LocaleProvider>
    )
  );
};

MessagesProvider.propTypes = {
  children: PropTypes.node,
};

export {MessagesProvider, useUpdateLocale};
