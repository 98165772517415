import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {Platform} from 'react-native';
import PropTypes from 'prop-types';

import {AuthApplication} from '@application/Auth';
import {eventManager} from '@application/Event';
import {NavigationEvent} from '@domain/model/Event';
import {useSentryForPayments} from '@modules/payments/hooks/useSentryForPayments';

export const AppContext = createContext();

const IS_NATIVE = Platform.OS === 'android' || Platform.OS === 'ios';
const AVAILABLE_STATES = ['stripe'];

export const AppProvider = ({children}) => {
  const [authorization, setAuthorization] = useState(undefined);
  const [header, setHeader] = useState(undefined);
  const [isPanelVisible, togglePanelVisibility] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({});
  const [isFirstLogin, setIsFirstLogin] = useState(undefined);
  useSentryForPayments();

  useEffect(() => {
    if (authorization && isFirstLogin) {
      setIsFirstLogin(false);
      AuthApplication.setHasLoggedIn();

      if (IS_NATIVE) {
        eventManager.emit(NavigationEvent.ONBOARDING_FINISHED);
      }
    }
  }, [authorization, isFirstLogin]);

  useEffect(() => {
    AuthApplication.isFirstLogin().then(setIsFirstLogin);

    const checkAuthorization = async () => setAuthorization(await AuthApplication.isLoggedIn());
    if (authorization === undefined) checkAuthorization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setAppLoading = useCallback(next => {
    // This hacky timeout is mandatory as the loading state of the application is so poorly managed.
    // We should get rid of this asap.
    if (next) {
      setLoading(next);
    } else {
      setTimeout(() => setLoading(next), 50);
    }
  }, []);

  const value = useMemo(
    () => ({
      authorization,
      header,
      isFirstLogin,
      isPanelVisible,
      loading,
      state,
      setAuthorization,
      setHeader,
      setLoading: setAppLoading,
      setState: (key, value) => {
        if (AVAILABLE_STATES.includes(key)) setState({...state, [key]: value});
      },
      togglePanelVisibility,
    }),
    [authorization, header, isFirstLogin, isPanelVisible, loading, state, setAppLoading],
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useApp = () => useContext(AppContext);
