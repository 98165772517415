import {ExperimentEvent} from '@domain/model/Event';

const sendToKameleoon = async (experimentName, experimentUserVariation) => {
  const eventToKml = {
    event: ExperimentEvent.EXPERIMENT_APPLIED,
    eventAction: experimentUserVariation,
    eventLabel: experimentName,
  };
  window.postMessage(eventToKml, window.location.origin);
};

export {sendToKameleoon};
