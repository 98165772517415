import React from 'react';

import {PromoCodesProvider as ActualPromoCodesProvider} from '@lookiero/promocodes-front';
import {usePromoUrlSetup} from '@modules/promoCodes/hooks/usePromoUrlSetup';

export const PromoCodesProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  usePromoUrlSetup();

  return <ActualPromoCodesProvider>{children}</ActualPromoCodesProvider>;
};
