import {apiFeatureFlag} from '@infra/api/rest/domain/model/FeatureFlag/apiFeatureFlag';
import {apiSite} from '@infra/api/rest/domain/model/Site/apiSite';

import {apiAuth} from './domain/model/Auth/apiAuth';

const apiRest = {
  recoverPassword: apiAuth.recoverPassword,
  getFeatureFlags: apiFeatureFlag.getFeatureFlags,
  getMessagesByLocale: apiSite.getMessagesByLocale,
  getSettingsByCountry: apiSite.getSettingsByCountry,
};

export {apiRest};
