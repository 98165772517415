import {AuthRepository} from '@domain/model/Auth/AuthRepository';
import {AuthLoginGoogle} from '@domain/model/Auth/AuthLoginGoogle';

const HandleGoogleLogin = async ({
  loginCommand,
  apiAuthRepository,
}: {
  loginCommand: AuthLoginGoogle;
  apiAuthRepository: AuthRepository;
}): Promise<string> => {
  try {
    const token = apiAuthRepository.loginWithGoogle(loginCommand);
    return Promise.resolve(token);
  } catch (error) {
    return Promise.reject(error);
  }
};

export {HandleGoogleLogin};
