import {PROJECT_ID as CHECKOUT_PROJECT_ID} from '../modules/checkout/sentryConfig';
import {PROJECT_ID as LOOK_AND_LIKE_PROJECT_ID} from '../modules/lookAndLike/sentryConfig';
import {PROJECT_ID as QUIZ_PROJECT_ID} from '../modules/quiz/sentryConfig';
import {PROJECT_ID as STYLE_PROFILE_PROJECT_ID} from '../modules/styleProfile/sentryConfig';

interface IsStySpSentryProjectFunction {
  (projectId: string): boolean;
}

const isStySpSentryProject: IsStySpSentryProjectFunction = projectId =>
  [LOOK_AND_LIKE_PROJECT_ID, CHECKOUT_PROJECT_ID, QUIZ_PROJECT_ID, STYLE_PROFILE_PROJECT_ID].includes(projectId);

export {isStySpSentryProject};
