import {SessionStorage} from '@infra/api/SessionStorage/SessionStorage';

const FIRST_SESSION_KEY = 'FIRST_SESSION_KEY';

function applyFirstSession(): void {
  SessionStorage.setItem(FIRST_SESSION_KEY, String(true));
}

function clearFirstSession(): void {
  SessionStorage.removeItem(FIRST_SESSION_KEY);
}

const isFirstSession = (): boolean => Boolean(SessionStorage.getItem(FIRST_SESSION_KEY));

export {applyFirstSession, isFirstSession, clearFirstSession};
