import {Theme} from '@lookiero/aurora';
import {StyleSheet} from 'react-native';
import {iOSInAppBrowserBannerHeight} from 'ui/helpers';

const {colorBgPrimary} = Theme.get();

const style = StyleSheet.create({
  inAppBrowserBanner: {
    alignItems: 'center',
    backgroundColor: colorBgPrimary,
    display: 'flex',
    flexDirection: 'row',
    height: iOSInAppBrowserBannerHeight,
    justifyContent: 'center',
    top: 0,
    width: '100%',
    zIndex: 3,
  },
});

export {style};
