import React, {FC} from 'react';
import {Platform} from 'react-native';
import {ErrorHandler, ErrorHandlerProvider} from '@lookiero/user-errorhandler-front';
import {getAuth} from '@infra/api/authManager/authManager';
import {setPaymentsBridge} from '@lookiero/payments-front';
import {PromoCodesProvider} from '@modules/promoCodes/providers/PromoCodesProvider';
import {EventProvider} from '@lookiero/event';
import {emitUserEvent} from '@lookiero/user-tracking-front';

import {MessagesProvider} from '@i18n';
import {BASENAME} from '../../modules/routing/routes.definition';

import {
  FeatureFlagsProvider,
  SettingsProvider,
  ShopProvider,
  useFeatureFlags,
  UserOverallInfoProvider,
} from '@components/_context';
import {ModalManagerProvider} from '@components/_context/ModalManager';
import {HeadManager} from '@components/templates/HeadManager';
import {errorParser, ErrorReporter, IGNORED_ERRORS} from 'errors';
import './globals';
import packageJson from '../../../native/package.json';

import {AppProvider} from './App.context';
import {getLocale, useReleaseVersion} from '../helpers';
import {KeyboardAvoidingView} from '../components/layouts/keyboardAvoidingView/KeyboardAvoidingView';
import {useAvoidKeyboard} from '../components/layouts/keyboardAvoidingView/useAvoidKeyboard';
import {PaymentsContextProvider} from '@payments/components/PaymentsContext';

import {GoogleSignInProvider} from '@components/_context/GoogleSignInContext';
import {ConfigurationApplication} from '@application/Config';
import {Routes} from '../../modules/routing/components/Routes';
import {Router} from '../../modules/routing/router/Router';
import {WebviewProvider} from './components/Webview/Webview.context';
import {Aurora} from './App.Aurora';

import {AppLinking} from './App.Linking';
import {ScrollView} from '@components/layouts/layout/components/scrollView/ScrollView';
import {GestureHandlerRootView} from 'react-native-gesture-handler';
import {FirebasePerformanceWrapper} from './components/Monitoring/FirebasePerformanceWrapper';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {AppBootstrap} from './App.Bootstrap';

const IS_WEB = Platform.OS === 'web';

const RELEASE = `${process.env.EXPO_PUBLIC_RELEASE || 'no-release'}-${process.env.CONFIG_MODE}`;

export const configurePayments = (): void => {
  const configuration = ConfigurationApplication.retrieve();
  const host = configuration.environment === 'PRODUCTION' ? 'https://lookiero.com' : 'https://web2.dev.aws.lookiero.es';

  setPaymentsBridge({
    getToken: getAuth,
    stripeKey: configuration.stripeKey,
    stripeUkKey: configuration.stripeUKKey,
    commonAssetsPath: configuration.commonAssetsPath,
    paymentsAssetsPath: configuration.paymentAssetsPath,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useAvoidKeyboard,
    emitTrackingEvent: emitUserEvent,
    appVersion: packageJson.version,
    graphqlUri: `${!IS_WEB ? host : ''}/graphql`,
    useFeatureFlags,
    locale: getLocale,
    scrollView: ScrollView,
  });
};

const App: FC = () => {
  const {appVersion, appPrivateVersion} = useReleaseVersion();

  return (
    <Router basename={IS_WEB ? BASENAME : undefined}>
      <FirebasePerformanceWrapper>
        <ErrorHandlerProvider
          dsn={
            IS_WEB
              ? 'https://b880b7233a004016981a8a1553ad3140@o179049.ingest.sentry.io/1454233'
              : 'https://d34ee7cb457b451aaeed7a5ca7249e43@o179049.ingest.sentry.io/5552335'
          }
          ignoredErrors={IGNORED_ERRORS}
          environment={ConfigurationApplication.retrieve().environment}
          release={IS_WEB ? RELEASE : `${appVersion}-${Platform.OS}`}
          dist={appPrivateVersion}
          beforeSend={errorParser}
        >
          <ErrorHandler section="app">
            <AppLinking />
            <FeatureFlagsProvider>
              <MessagesProvider>
                <SettingsProvider>
                  <PaymentsContextProvider>
                    <EventProvider>
                      <AppProvider>
                        <ShopProvider>
                          <WebviewProvider>
                            <ModalManagerProvider>
                              <PromoCodesProvider>
                                <UserOverallInfoProvider>
                                  <SafeAreaProvider>
                                    <KeyboardAvoidingView>
                                      <GestureHandlerRootView style={{flex: 1}}>
                                        <Aurora>
                                          <HeadManager />
                                          <ErrorReporter />
                                          <GoogleSignInProvider>
                                            <Routes />
                                          </GoogleSignInProvider>

                                          <AppBootstrap />
                                        </Aurora>
                                      </GestureHandlerRootView>
                                    </KeyboardAvoidingView>
                                  </SafeAreaProvider>
                                </UserOverallInfoProvider>
                              </PromoCodesProvider>
                            </ModalManagerProvider>
                          </WebviewProvider>
                        </ShopProvider>
                      </AppProvider>
                    </EventProvider>
                  </PaymentsContextProvider>
                </SettingsProvider>
              </MessagesProvider>
            </FeatureFlagsProvider>
          </ErrorHandler>
        </ErrorHandlerProvider>
      </FirebasePerformanceWrapper>
    </Router>
  );
};

export {App};
