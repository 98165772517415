const TEXT = {
  DOWNLOAD: {id: 'download.app.text'},
};

enum SOURCE {
  SIDE_MENU = 'sideMenu',
  MY_LK_PLACED_ORDER = 'myLkPlacedOrder',
  MY_LK_TRACKING_ORDER = 'myLkTrackingOrder',
  SUBSCRIPTION = 'myLkSubscription',
  MY_LK_CHECKOUT_WELCOME = 'myLKCheckoutWelcome',
  DOWNLOAD_APP_MODAL = 'downloadAppModal',
}

enum TYPE {
  IOS = 'IOS',
  ANDROID = 'Android',
}

export {TEXT, SOURCE, TYPE};
