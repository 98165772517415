import React, {FC} from 'react';
import {Navigate, To, useLocation} from 'react-router-native';
import {Path} from '../routes.definition';
import {useApp} from '@app';
import {extractRedirectionTargetFromSearch} from './AutomaticRedirects.controller';

interface AutmaticRedirectsProps {
  readonly authorisedFallback: Path;
  readonly unauthorisedFallback: Path;
  readonly replaceOnNavigate: boolean;
}
const AutomaticRedirects: FC<AutmaticRedirectsProps> = ({
  authorisedFallback,
  replaceOnNavigate,
  unauthorisedFallback,
}) => {
  const {authorization} = useApp();
  const {pathname, search} = useLocation();

  // If the user is logged in and the redirect query parameter is present in the URL,
  // we redirect to the target path specified in the query parameter.
  // Otherwise, we redirect to the authorised fallback path.
  const redirectionTarget = extractRedirectionTargetFromSearch(search);
  const queryParams = redirectionTarget?.pathname ? redirectionTarget?.queryParams : search;
  const destination: To = authorization
    ? {pathname: redirectionTarget?.pathname || authorisedFallback, search: queryParams}
    : {
        pathname: unauthorisedFallback,
        ...(pathname !== '/' ? {search: '?redirect=' + encodeURIComponent(pathname + search)} : {}),
      };

  return <Navigate to={destination} replace={replaceOnNavigate} />;
};

export {AutomaticRedirects};
