import {FeatureFlag} from '@domain/model/FeatureFlag/FeatureFlag';
import {apiRest} from '@infra/api/rest/api';

const getFeatureFlags = (): Promise<FeatureFlag[]> => {
  return apiRest.getFeatureFlags();
};

export const ApiFeatureFlagRepository = {
  getFeatureFlags,
};
