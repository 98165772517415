import {DomainEvent} from 'typings/domainEvent';
import {EventManager} from '@domain/model/Event/EventManager';

const EventUnsubscription =
  (eventManager: EventManager) =>
  (
    eventType: DomainEvent,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback: (args: any) => void,
  ): void => {
    return eventManager.unsubscribe(eventType, callback);
  };

export {EventUnsubscription};
