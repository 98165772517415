import React from 'react';
import {useIntl} from 'react-intl';
import {
  StylerProperties,
  // helpers
  ALIGN,
  // components
  Box,
  Text,
  TEXT_ROLES,
} from '@lookiero/aurora';

import {TITLE_PROPS} from '@app/App.config';

interface TitleProps extends StylerProperties {
  children?: string;
  id?: string;
  messageParams?: Record<string, string>;
}

export const Title = ({children = '', id, messageParams = {}, ...others}: TitleProps): JSX.Element => {
  const {formatMessage} = useIntl();

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Box {...TITLE_PROPS} {...others}>
      <Text
        align={ALIGN.LEFT}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        role={TEXT_ROLES.h1}
        heading
      >
        {id && formatMessage({id}, messageParams)}
        {children}
      </Text>
    </Box>
  );
};
