import {PICKUP_POINTS_ALL_FIELDS} from './PickupPointsAllFieldsQuery';
import {SHIPPING_ADDRESS_ALL_FIELDS} from './ShippingAddressAllFieldsQuery';
import {BILLING_ADDRESS_ALL_FIELDS} from './BillingAddressAllFieldsQuery';

const Queries = {
  PICKUP_POINTS_ALL_FIELDS,
  SHIPPING_ADDRESS_ALL_FIELDS,
  BILLING_ADDRESS_ALL_FIELDS,
};

export {Queries};
