import React from 'react';
import {useIntl} from 'react-intl';
import {useLocale, useStore} from '@components/hooks';
import {TEXT, TYPE} from './AppDownloadButtons.definitions';
import {style} from './AppDownloadButtons.style';
import {View, Text, Image, Link} from '@lookiero/aurora';
import {openExternalLink} from '@router/helpers';
import {Locale} from '@domain/model/Site/Locale';
import {eventManager} from '@application/Event';
import {NavigationEvent} from '@domain/model/Event';
import {useOverallInfo} from '@components/_context';
import {ConfigurationApplication} from '@application/Config';

const imgBase = `${ConfigurationApplication.retrieve().assetsPath}/images/app/download/`;
const appleImg = '/apple-store.svg';
const androidImg = '/google-play.svg';

type DownloadButtonProps = {
  title?: string;
  source?: string;
};

const DownloadButton = ({title, source = ''}: DownloadButtonProps): JSX.Element => {
  const {formatMessage} = useIntl();
  const {country} = useLocale() as Locale;
  const {iosStore, androidStore} = useStore();
  const {id: userId} = useOverallInfo();

  const handleDownloadApp = (url: string, type: TYPE): void => {
    void openExternalLink({url});

    eventManager.emit(NavigationEvent.DOWNLOAD_APP_BUTTONS, {
      userId,
      type,
      source,
    });
  };

  const appleOnPress = (): void => {
    handleDownloadApp(iosStore, TYPE.IOS);
  };

  const androidOnPress = (): void => {
    handleDownloadApp(androidStore, TYPE.ANDROID);
  };

  return (
    <>
      <Text detail level={1} style={style.title} accessibilityLabel="app-download-buttons-title">
        {title || formatMessage(TEXT.DOWNLOAD)}
      </Text>
      {country && (
        <View style={style.imgs}>
          <Link onPress={appleOnPress}>
            <Image alt="ios" src={imgBase + country + appleImg} style={style.apple} />
          </Link>
          <Link onPress={androidOnPress}>
            <Image alt="android" src={imgBase + country + androidImg} style={style.android} />
          </Link>
        </View>
      )}
    </>
  );
};

export {DownloadButton};
