const SATURDAY = 6;
const SUNDAY = 0;

const MILLISECONDS = 1000;
const SECONDS = 60;
const MINUTES = 60;
const HOURS = 24;
const DAYS_IN_MILLIS = MILLISECONDS * SECONDS * MINUTES * HOURS;

const toShortDateUTC = (date: Date): Date => new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

const toShortDate = (date: Date): Date => new Date(date.getFullYear(), date.getMonth(), date.getDate());

const isTheSameDay = (date1: Date, date2: Date): boolean =>
  toShortDate(date1).getTime() === toShortDate(date2).getTime();

const isToday = (date: string): boolean => toShortDate(new Date(date)).getTime() === toShortDate(new Date()).getTime();

const pad = (n: number, padLen = 2): string => n.toString().padStart(padLen, '0');

const formatDate = ({dateIn = new Date(), format = 'YYYY-MM-DD hh:mm:ss'} = {}): string => {
  const fTime: {[key: string]: number} = {
    YYYY: dateIn.getFullYear(),
    MM: dateIn.getMonth() + 1,
    DD: dateIn.getDate(),
    hh: dateIn.getHours(),
    mm: dateIn.getMinutes(),
    ss: dateIn.getSeconds(),
  };
  return format.replace(/YYYY|MM|DD|hh|mm|ss/gi, (matched: string) => pad(fTime[matched]));
};

const isStartDateSmallerThanEndDate = ({
  startDate,
  endDate = new Date().toString(),
}: {
  startDate: string;
  endDate: string | undefined;
}): boolean => new Date(startDate).getTime() < new Date(endDate).getTime();

const getDaysDiff = (startDate: Date | string, endDate = new Date()): number => {
  const diff = new Date(startDate).setHours(0, 0, 0, 0) - new Date(endDate).setHours(0, 0, 0, 0);
  return Math.floor(diff / DAYS_IN_MILLIS);
};

const isWeekend = (day: number): boolean => day === SATURDAY || day === SUNDAY;

const toAvailableDate = (date: Date, unavailableDates: Date[] = []): Date => {
  const newDate = toShortDateUTC(date);

  const excluded = unavailableDates.map(value => toShortDateUTC(value).getTime());

  for (;;) {
    if (isWeekend(newDate.getDay()) || excluded.includes(newDate.getTime())) {
      newDate.setDate(newDate.getDate() + 1);
    } else {
      break;
    }
  }

  return newDate;
};

const stringToDate = (isoDateStr = ''): Date => {
  const [year, month, day] = isoDateStr.split('-').map(n => parseInt(n, 10));
  return new Date(Date.UTC(year, month - 1, day));
};

export {
  formatDate,
  isStartDateSmallerThanEndDate,
  isTheSameDay,
  getDaysDiff,
  isToday,
  stringToDate,
  toShortDateUTC,
  toAvailableDate,
};
