import {matchPath} from 'react-router-native';
import {Link} from '@modules/menu/Links';

export const LINK_LEVEL = 1;

export interface IsolatedPanelProps {
  readonly visible: boolean;
  readonly authorization: boolean;
  readonly links: Link[];
  readonly onClose: () => void;
  readonly openLink: (link: string) => void;
}

export const isMatchPath = (pathname: string, path: string): boolean => Boolean(matchPath(path, pathname));
