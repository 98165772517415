import {FeatureFlag} from '@domain/model/FeatureFlag/FeatureFlag';
import {fetch} from '@infra/api/fetch';

const getFeatureFlags = async (): Promise<FeatureFlag[]> => {
  try {
    const response = await fetch('/api/feature-flags');
    return response.json();
  } catch (e) {
    return Promise.reject(e);
  }
};

const apiFeatureFlag = {getFeatureFlags};

export {apiFeatureFlag};
