import {SiteDomain} from '@domain/model/Site/Site';
import {SITE} from './Site.definition';

export const getTLD = (hostname: string): string | null => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return hostname.indexOf('.') >= 0 ? hostname.split('.').pop()! : null;
};

export const matchSiteDomain = (tld: string | null): SiteDomain => {
  if (tld == null) return SITE.UK;
  const matchingSites = Object.values(SITE).filter(s => s === tld.toUpperCase());
  return matchingSites.length > 0 ? matchingSites[0] : SITE.UK;
};

export const getStore = async (): Promise<SiteDomain> => matchSiteDomain(getTLD(window.location.hostname));
