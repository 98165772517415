import StyleSheet from 'react-native-extended-stylesheet';
import {iOSInAppBrowserBannerHeight} from 'ui/helpers';

export const style = StyleSheet.create({
  container: {
    shadowColor: '$elevationColor',
    shadowOffset: '$elevationOffset',
    shadowOpacity: '$elevationOpacityM',
    shadowRadius: '$elevationRadius',
    marginTop: '$space4',
    marginHorizontal: '$space4',
  },
  inAppContainer: {
    top: iOSInAppBrowserBannerHeight + 5,
  },
});
