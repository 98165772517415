import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {
  // Components
  Link,
  Text,
  View,
  // Helpers
  SIZE,
  COLOR,
} from '@lookiero/aurora';

import {useToast, generateUid} from '@components/_context';
import {ModalReturnInstructions} from '@components/atoms';
import {OrderService} from '@domain/model/Order';
import {TEXT} from './NotificationReturnInstructions.definition';

interface NotificationReturnInstructionsProps {
  readonly orderPendingReturn: OrderService | undefined;
}

const NotificationReturnInstructions: React.FC<NotificationReturnInstructionsProps> = ({orderPendingReturn}) => {
  const {formatMessage} = useIntl();
  const uid = useMemo<string>(() => generateUid(), []);
  const toast = useToast(uid);
  const [modalInstructions, setModalInstructions] = useState<boolean>(false);

  const onOpenInstructionsModal = useCallback(() => {
    setModalInstructions(true);
    toast.hide(uid);
  }, [toast, uid]);
  const onCloseInstructionsModal = useCallback(() => setModalInstructions(false), []);

  useEffect(() => {
    if (orderPendingReturn) {
      toast.showInformation({
        message: (
          <>
            <View wide>
              <Text detail level={2} color={COLOR.BG_BASE}>
                {formatMessage(TEXT.ACTION_RETURN_TITLE)}
              </Text>
            </View>
            <Link detail level={2} onPress={onOpenInstructionsModal} underlined color={COLOR.BG_BASE}>
              {formatMessage(TEXT.ACTION_RETURN_LINK)}
            </Link>
          </>
        ),
        settings: {
          timeoutClose: 0,
        },
      });
      return () => {
        toast.hide(uid);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {orderPendingReturn && modalInstructions ? (
        <ModalReturnInstructions
          orderReference={`LK-${orderPendingReturn.id}`}
          serviceId={undefined}
          layer={SIZE.XL}
          onClose={onCloseInstructionsModal}
          isVisible={true}
        />
      ) : null}
    </>
  );
};

export {NotificationReturnInstructions};
