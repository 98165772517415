import {RESET_PASSWORD} from './ResetPasswordMutation';
import {BILLING_ADDRESS} from './BillingAddressMutation';
import {SHIPPING_ADDRESS} from './ShippingAddressMutation';
import {SUBSCRIPTION} from './SubscriptionMutation';
import {UPDATE_EMAIL} from './UpdateEmailMutation';
import {UPDATE_NEXT_ORDER_DATE} from './UpdateNextOrderDate';
import {SKIP_MONTH} from './SkipMonth';

const Mutations = {
  BILLING_ADDRESS,
  RESET_PASSWORD,
  SHIPPING_ADDRESS,
  SUBSCRIPTION,
  UPDATE_EMAIL,
  UPDATE_NEXT_ORDER_DATE,
  SKIP_MONTH,
};

export {Mutations};
