import PropTypes from 'prop-types';

export const PROPTYPE_SHIPPING_ADDRESS_ACCOUNT = PropTypes.shape({
  name: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  postCode: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  delivery: PropTypes.shape({
    pickupId: PropTypes.string.isRequired,
    pickupName: PropTypes.string.isRequired,
  }),
});

export const PROPTYPE_SHIPPING_ADDRESS_ACCOUNT_VALUES = PropTypes.shape({
  name: PropTypes.string,
  lastName: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  phone: PropTypes.string,
  delivery: PropTypes.shape({
    pickupId: PropTypes.string.isRequired,
    pickupName: PropTypes.string.isRequired,
    courier: PropTypes.string.isRequired,
    postCode: PropTypes.string.isRequired,
  }),
});
