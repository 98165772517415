import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {
  // Helpers
  SPACE,
  // Components
  Button,
  Calendar,
} from '@lookiero/aurora';

import {getLocale} from '@helpers';

import {IDS, TEXT} from './ModalDayPicker.definition';
import {MountableModal} from '@components/molecules';

type ModalDayPickerProps = {
  date: Date | string;
  minDate?: string;
  maxDate?: string;
  unavailableDates?: string[];
  isVisible?: boolean;
  onClose: () => void;
  onSubmit: (date: string) => void;
};

export const ModalDayPicker = ({
  date,
  isVisible,
  onClose,
  onSubmit,
  minDate,
  maxDate,
  unavailableDates,
}: ModalDayPickerProps): JSX.Element => {
  const {formatMessage} = useIntl();

  const [nextDate, setNextDate] = useState(date);
  const [locale, setLocale] = useState<string | undefined>(undefined);

  useEffect(() => {
    const initializeDayPicker = async (): Promise<void> => {
      try {
        await getLocale().then(setLocale);
      } catch (error) {
        onClose();
        // TODO Error toast notification
      }
    };
    initializeDayPicker();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (): void => {
    const date = nextDate instanceof Date ? nextDate.toISOString().split('T')[0] : nextDate;
    onSubmit(date);
  };

  return (
    <MountableModal
      portalID="modal-mylookiero-day-picker"
      isVisible={Boolean(isVisible)}
      title={formatMessage(TEXT.DESCRIPTION)}
      onClose={onClose}
    >
      <Calendar
        disabledPast
        disabledToday
        marginBottom={SPACE.SPACE_6}
        locale={locale}
        onChange={setNextDate}
        value={date as string} // TODO: This **might** be a Date object and break
        wide
        disabledDates={unavailableDates}
        from={minDate}
        to={maxDate}
      />
      <Button onPress={handleSubmit} nativeID={IDS.SAVE_DAY_BUTTON} testID="save-calendar-button">
        {formatMessage(TEXT.CTA)}
      </Button>
    </MountableModal>
  );
};
