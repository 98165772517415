interface OpenExternalLinkProps {
  url: string;
  createLink?: boolean;
  sameWindow?: boolean;
}

export const openExternalLink = async ({url, createLink = false, sameWindow = false}: OpenExternalLinkProps) => {
  const target = sameWindow ? '_self' : '_blank';

  if (createLink) {
    const linkEl = document.createElement('a');
    linkEl.setAttribute('href', url);
    linkEl.setAttribute('target', target);
    linkEl.click();
    linkEl.remove();
  } else {
    window.open(url, target);
  }
};
