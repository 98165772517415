import PropTypes from 'prop-types';

export const ASPECT_RATIO = 1.25;

export const MARGIN_ITEM = 1;
export const MAX_WIDTH = 1920;

export const WIDTH_RATIO = 0.66;
export const WIDTH_RATIO_ITEMS_PER_ROW = 0.5;

export const GalleryPropTypes = {
  children: PropTypes.node,
  fitHeight: PropTypes.bool,
  itemsPerRow: PropTypes.number,
};
