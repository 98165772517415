import {executeMutation} from '@infra/api/graphQL/client';
import {Mutations} from '@infra/api/graphQL/domain/model/Account/mutations';

const resetPassword = (password: string): Promise<void> => {
  return executeMutation<void>(Mutations.RESET_PASSWORD, {
    password,
  });
};

export const ApiResetPasswordRepository = {
  resetPassword,
};
