import {registerRootComponent} from 'expo';
import {activateKeepAwake} from 'expo-keep-awake';
import {App, configurePayments} from './src/ui/app/App';
import {configurePromoCodes} from '@modules/promoCodes/config';
import {enableLegacyWebImplementation} from 'react-native-gesture-handler';

/**
 * This is required for the Slider (checkout's new implementation) to properly work within a ScrollView.
 */
enableLegacyWebImplementation(true);

if (__DEV__) {
  activateKeepAwake();
}

configurePayments();
configurePromoCodes();

registerRootComponent(App);
