import {ShipmentApplication} from '@application/Shipment';
import {Shipment} from '@domain/model/Shipment/Shipment';
import {Country} from '@domain/model/Site/Locale';
import {COURIER_RETURN_ENABLED} from './ModalReturnInstructions.definition';

const isCourierReturnEnabled = (courier: string, country: Country): boolean => {
  return COURIER_RETURN_ENABLED[courier] && COURIER_RETURN_ENABLED[courier][country] ? true : false;
};

const extractLink = (shipment: Shipment, type: string): string | undefined => {
  return shipment.links
    .filter(link => link.type === type)
    .map(link => link.href)
    .pop();
};

const retrieveShipmentByOrderReference = async (orderReference: string): Promise<Shipment> => {
  return ShipmentApplication.getShipmentByOrderReference(orderReference);
};

const retrieveShipmentByServiceId = async (serviceId: string): Promise<Shipment> => {
  return ShipmentApplication.getShipmentByServiceId(serviceId);
};

const retrieveLinks = (shipment: Shipment | undefined): {[k: string]: string | undefined} | undefined => {
  if (!shipment) return undefined;
  const links: {[k: string]: string | undefined} = {};

  links.searchPickupPoint = extractLink(shipment, 'SEARCH_PICKUP_POINT');
  links.requestPickup = extractLink(shipment, 'REQUEST_PICKUP');
  links.alternativeRequestPickup = extractLink(shipment, 'ALTERNATIVE_REQUEST_PICKUP');

  return links;
};

export {retrieveShipmentByOrderReference, retrieveShipmentByServiceId, retrieveLinks, isCourierReturnEnabled};
