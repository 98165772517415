import gql from 'graphql-tag';

const RETRIEVE_TOKEN = gql`
  mutation retrieveToken($username: String!, $password: String!) {
    retrieveToken(username: $username, password: $password) {
      token
    }
  }
`;

export {RETRIEVE_TOKEN};
