const addressFields = `
  name
  lastName
  address
  postCode
  city
  phone`;

const pickupAddressFields = `
  address
  postCode
  city`;

const deliveryFields = `
  courier
  pickupId
  pickupName`;

const openingDays = `
openingDays {
  dayOfWeek
  hours {
      open
      close
  }
  closed
  open24Hours
}`;

export const ACCOUNT_FIELDS = {addressFields, pickupAddressFields, deliveryFields, openingDays};
