import {Subscription} from '@domain/model/Account/Subscription/Subscription';
import {SubscriptionDTO} from '@domain/model/Account/Subscription/SubscriptionDTO';

import {executeMutation} from '@infra/api/graphQL/client';
import {Mutations} from '@infra/api/graphQL/domain/model/Account/mutations';

const setSubscription = (subscription: Subscription): Promise<SubscriptionDTO> => {
  return executeMutation<SubscriptionDTO>(Mutations.SUBSCRIPTION, {
    ...subscription,
  });
};

const skipMonth = (): Promise<SubscriptionDTO> => executeMutation<SubscriptionDTO>(Mutations.SKIP_MONTH, {});

export const ApiSubscriptionRepository = {
  setSubscription,
  skipMonth,
};
