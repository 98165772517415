import React, {createContext, useContext, useState} from 'react';
import PropTypes from 'prop-types';

import {emptyFn} from '@helpers';

const PanelContext = createContext({
  isLeftPanelVisible: false,
  isRightPanelVisible: false,
  toggleLeftPanel: emptyFn,
  toggleRightPanel: emptyFn,
});

const PanelProvider = ({children}) => {
  const [isLeftPanelVisible, setLeftPanelVisible] = useState(false);
  const [isRightPanelVisible, setRightPanelVisible] = useState(false);

  const toggleLeftPanel = value => setLeftPanelVisible(value ? value : !isLeftPanelVisible);
  const toggleRightPanel = value => setRightPanelVisible(value ? value : !isRightPanelVisible);

  return (
    <PanelContext.Provider value={{isLeftPanelVisible, isRightPanelVisible, toggleLeftPanel, toggleRightPanel}}>
      {children}
    </PanelContext.Provider>
  );
};

PanelProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const usePanel = () => useContext(PanelContext);

export {usePanel, PanelProvider};
