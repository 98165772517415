import {useEffect, useState} from 'react';
import {Platform} from 'react-native';
import {sendException} from '../../errors/errorLogger';

import {mendelService} from './mendel';

const VARIANT_STATE = {
  LOADING: 'LOADING',
  NOT_ASSIGNABLE: undefined,
} as const;

export type VariantState = (typeof VARIANT_STATE)[keyof typeof VARIANT_STATE] | string;

/**
 * @deprecated Please use useExperiment hook instead.
 * There is a jira ticket to migrate existing useAssignMendelVariant experiments to useExperiment.
 * https://lookiero.atlassian.net/browse/LK-23675
 */
const useAssignMendelVariant = (experimentName: string, condition = true): VariantState => {
  const [currentVariant, setCurrentVariant] = useState<VariantState>(VARIANT_STATE.LOADING);

  useEffect(() => {
    if (Platform.OS === 'web' && condition) {
      (async () => {
        try {
          const userVariant = await mendelService.getVariantByExperimentName({name: experimentName});
          setCurrentVariant(userVariant);
        } catch (e) {
          setCurrentVariant(VARIANT_STATE.NOT_ASSIGNABLE);
          sendException(e);
        }
      })();
    } else {
      setCurrentVariant(VARIANT_STATE.NOT_ASSIGNABLE);
    }
  }, [experimentName, condition]);

  return currentVariant;
};

const isLoaded = (variant: VariantState): boolean => variant !== VARIANT_STATE.LOADING;

export {useAssignMendelVariant, isLoaded};
