import React, {ReactNode} from 'react';
import {ScrollView} from 'react-native';
import {COLOR, Modal, Portal} from '@lookiero/aurora';

import {useFBInAppBrowser, useMountablePortal} from '@components/hooks';

type MountableModalProps = {
  children?: React.ReactNode;
  isVisible: boolean;
  title?: string;
  description?: ReactNode;
  onClose?: () => void;
  portalID: string;
  fullHeight?: boolean;
  style?: object;
  color?: COLOR;
  size?: Record<string, string>;
  nativeID?: string;
  testID?: string;
  contentStyle?: object;
  scrollable?: boolean;
  scrollToEnd?: boolean;
  scrollRef?: React.Ref<ScrollView>;
};

const MountableModal = ({
  children,
  isVisible,
  nativeID,
  portalID,
  fullHeight,
  scrollable,
  style,
  contentStyle,
  scrollToEnd,
  scrollRef,
  ...rest
}: MountableModalProps): JSX.Element => {
  const {isMountedAndVisible, showComponent: showModal} = useMountablePortal({isVisible});
  const {fBModalStyles, fBModalContentStyles, fBModalScrollableContent} = useFBInAppBrowser({
    fullHeightModal: Boolean(fullHeight),
    modalWithScrollableContent: Boolean(scrollable),
    isModalVisible: showModal,
  });

  return isMountedAndVisible ? (
    <Portal id={portalID}>
      <Modal
        isVisible={showModal}
        nativeID={nativeID}
        fullHeight={fullHeight}
        scrollable={fBModalScrollableContent}
        style={[style, fBModalStyles]}
        scrollToEnd={scrollToEnd}
        contentStyle={[contentStyle, fBModalContentStyles]}
        scrollRef={scrollRef}
        {...rest}
      >
        {children}
      </Modal>
    </Portal>
  ) : (
    <></>
  );
};

export {MountableModal};
