import React, {useCallback, useEffect, useState, FC, useMemo} from 'react';
import {SIZE, ScrollView, useDevice} from '@lookiero/aurora';

import {
  retrieveLinks,
  retrieveShipmentByOrderReference,
  retrieveShipmentByServiceId,
} from './ModalReturnInstructions.controller';
import {useLocale} from '@components/hooks';
import {useIntl} from 'react-intl';
import {useToast} from '@components/_context';
import {COURIER, TEXT} from './ModalReturnInstructions.definition';
import {ModalReturnInstructionsSEUR} from './courier/ModalReturnInstructions.SEUR';
import {ModalReturnInstructionsGLS} from './courier/ModalReturnInstructions.GLS';
import {ModalReturnInstructionsMR} from './courier/ModalReturnInstructions.MR';
import {ModalReturnInstructionsEVRI} from './courier/ModalReturnInstructions.EVRI';
import {ModalReturnInstructionsUPS} from './courier/ModalReturnInstructions.UPS';
import {ModalReturnInstructionsINPOST} from './courier/ModalReturnInstructions.INPOST';
import {ModalReturnInstructionsSWISSPOST} from './courier/ModalReturnInstructions.SWISSPOST';
import {ModalReturnInstructionsDHL} from './courier/ModalReturnInstructions.DHL';
import {MountableModal} from '@components/molecules';

import {Shipment} from '@domain/model/Shipment/Shipment';
import {Locale} from '@domain/model/Site/Locale';

interface ModalReturnInstructions {
  readonly orderReference?: string;
  readonly serviceId?: string;
  readonly isVisible: boolean;
  readonly onClose?: () => void;
  readonly layer?: SIZE;
}

export const ModalReturnInstructions: FC<ModalReturnInstructions> = ({
  orderReference,
  serviceId,
  onClose,
  ...others
}) => {
  const [loading, setLoading] = useState(true);
  const [errorLoading, setErrorLoading] = useState(false);
  const [shipment, setShipment] = useState<Shipment | undefined>(undefined);
  const toast = useToast();
  const locale = useLocale() as Locale;

  const {formatMessage} = useIntl();

  const {screen} = useDevice();
  const isMobileDevice = useMemo(() => screen.L === false, [screen]);

  const getModal = useCallback(
    (courier: string): JSX.Element | undefined => {
      switch (courier) {
        case COURIER.SEUR:
          return (
            <ModalReturnInstructionsSEUR
              country={locale.country}
              links={retrieveLinks(shipment) as {[k: string]: string | undefined}}
              orderReference={orderReference}
            />
          );
        case COURIER.GLS:
          return (
            <ModalReturnInstructionsGLS
              country={locale.country}
              links={retrieveLinks(shipment) as {[k: string]: string | undefined}}
              orderReference={orderReference}
            />
          );
        case COURIER.MR:
          return (
            <ModalReturnInstructionsMR
              country={locale.country}
              links={retrieveLinks(shipment) as {[k: string]: string | undefined}}
              orderReference={orderReference}
            />
          );
        case COURIER.IN_POST:
          return (
            <ModalReturnInstructionsINPOST
              country={locale.country}
              links={retrieveLinks(shipment) as {[k: string]: string | undefined}}
              orderReference={orderReference}
            />
          );
        case COURIER.EVRI:
          return (
            <ModalReturnInstructionsEVRI
              links={retrieveLinks(shipment) as {[k: string]: string | undefined}}
              orderReference={orderReference}
            />
          );
        case COURIER.SWISS_POST:
          return (
            <ModalReturnInstructionsSWISSPOST
              country={locale.country}
              links={retrieveLinks(shipment) as {[k: string]: string | undefined}}
              orderReference={orderReference}
            />
          );
        case COURIER.DHL:
          return (
            <ModalReturnInstructionsDHL
              country={locale.country}
              links={retrieveLinks(shipment) as {[k: string]: string | undefined}}
              orderReference={orderReference}
            />
          );
        case COURIER.UPS:
          return (
            <ModalReturnInstructionsUPS
              links={retrieveLinks(shipment) as {[k: string]: string | undefined}}
              orderReference={orderReference}
              country={locale.country}
            />
          );
      }
    },
    [locale, orderReference, shipment],
  );

  const renderErrorLoading = useCallback(() => {
    if (errorLoading) {
      toast.showError({
        message: formatMessage(TEXT.INSTRUCTION_ERROR),
      });
      setErrorLoading(false);
    }
  }, [errorLoading, formatMessage, toast]);

  useEffect(() => {
    (async () => {
      try {
        const shipment =
          orderReference !== undefined
            ? await retrieveShipmentByOrderReference(orderReference)
            : await retrieveShipmentByServiceId(serviceId as string);
        setShipment(shipment);
      } catch (e) {
        setErrorLoading(true);
      }
      setLoading(false);
    })();
  }, [orderReference, serviceId]);

  return (
    <>
      {!loading && shipment && (
        <MountableModal
          {...others}
          portalID="modal-return-instructions-new"
          scrollable={true}
          fullHeight={isMobileDevice}
          onClose={onClose}
          title={formatMessage(orderReference !== undefined ? TEXT.TITLE : TEXT.SHOP_TITLE)}
          testID="modal-return-instructions"
        >
          <ScrollView style={{flex: 1}}>{getModal(shipment.return?.courier as string)}</ScrollView>
        </MountableModal>
      )}
      {!loading && !shipment && renderErrorLoading()}
    </>
  );
};
