import React from 'react';
import PropTypes from 'prop-types';
import {SIZE} from '@lookiero/aurora';

import {emptyFn} from '@helpers';

import {FormField} from './FormField';

const FormFieldsBuilder = ({
  configuration = [],
  handleBlur = emptyFn,
  handleChange = emptyFn,
  getFieldMeta = emptyFn,
  values = {},
  forceShowErrors = false,
}) =>
  configuration.map(({uniqueKey, label, component, marginBottom, marginTop, handleUserInput, type, ...rest}, index) => (
    <FormField
      key={index}
      marginBottom={index === configuration.length - 1 ? SIZE.RESET : marginBottom}
      {...{
        uniqueKey,
        label,
        component,
        marginTop,
        handleBlur,
        handleUserInput,
        handleChange,
        getFieldMeta,
        values,
        type,
        forceShowErrors,
      }}
      {...rest}
    />
  ));

FormFieldsBuilder.propTypes = {
  configuration: PropTypes.arrayOf(
    PropTypes.shape({
      uniqueKey: PropTypes.string.isRequired,
      label: PropTypes.object.isRequired,
      component: PropTypes.func.isRequired,
      marginBottom: PropTypes.string,
      marginTop: PropTypes.string,
    }).isRequired,
  ).isRequired,
  getFieldMeta: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export {FormFieldsBuilder};
