import {DownloadButton} from '@components/molecules/AppDownloadButtons/AppDownloadButtons';
import {SOURCE} from '@components/molecules/AppDownloadButtons/AppDownloadButtons.definitions';
import {ALIGN, Button, BUTTON_VARIANT, COLOR, Header, Link as AuroraLink, Panel, SPACE, View} from '@lookiero/aurora';
import {Path} from '../../../../modules/routing/routes.definition';
import React, {FC, useCallback} from 'react';
import {useWindowDimensions} from 'react-native';
import {NavigateOptions, useNavigate} from 'react-router-native';
import {iOSInAppBrowserVideoAd} from 'ui/helpers';
import {style} from './IsolatedPanel.style';
import {TEXT} from './Panel.definition';
import {IsolatedPanelProps, LINK_LEVEL} from '@components/atoms/Panel/Panel.controller';
import {useIntl} from 'react-intl';
import {InAppBrowserBanner} from '../InAppBrowserBanner';

const IsolatedPanel: FC<IsolatedPanelProps> = ({visible, authorization, links, onClose}) => {
  const navigate = useNavigate();
  const {formatMessage} = useIntl();

  const handleNavigate = useCallback(
    (path: Path, options?: NavigateOptions) => {
      onClose();
      navigate(path, options);
    },
    [navigate, onClose],
  );

  const {width} = useWindowDimensions();
  const screenSize = {
    tablet: width >= 768,
    web: width >= 1280,
  };

  return (
    <Panel
      align={ALIGN.RIGHT}
      color={COLOR.BG_BASE}
      hasOverlay
      isVisible={visible}
      onClose={onClose}
      style={[screenSize.tablet && style.widthTablet, screenSize.web && style.widthWeb]}
    >
      {iOSInAppBrowserVideoAd && <InAppBrowserBanner />}

      <View style={[style.content, iOSInAppBrowserVideoAd && style.iOSInAppBrowser]}>
        <Header onClose={onClose} />
        <View style={style.body}>
          <View style={style.container}>
            <View>
              {links.map((item, index) => (
                <AuroraLink
                  action
                  level={LINK_LEVEL}
                  href={item.external ? item.path : undefined}
                  key={item.path}
                  marginBottom={index < links.length - 1 ? SPACE.SPACE_5 : undefined}
                  testID={item.testID}
                  target={item.external ? '_blank' : undefined}
                >
                  {formatMessage({id: item.translationKey})}
                </AuroraLink>
              ))}
            </View>
            {!authorization && (
              <View>
                <Button
                  marginBottom={SPACE.SPACE_4}
                  onPress={() => handleNavigate(Path.QUIZ_HOME, {state: {from: Path.LOGIN}})}
                  variant={BUTTON_VARIANT.PRIMARY}
                  small
                >
                  {formatMessage(TEXT.QUIZ_CTA)}
                </Button>
                <Button variant={BUTTON_VARIANT.SECONDARY} onPress={() => handleNavigate(Path.LOGIN)} small>
                  {formatMessage(TEXT.LOGIN_CTA)}
                </Button>
              </View>
            )}
          </View>
          <View>{authorization && <DownloadButton source={SOURCE.SIDE_MENU} />}</View>
        </View>
      </View>
    </Panel>
  );
};

export {IsolatedPanel};
