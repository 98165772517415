import {Mendel as MendelClient} from '@lookiero/mendel-web-client';

import {eventManager} from '@application/Event';
import {ExperimentEvent} from '@domain/model/Event';
import {MendelService} from './_types';
import {getScope} from './getScope';

const init = (): MendelClient => {
  const mendel = new MendelClient();
  mendel.setApiHost('/mendel');
  mendel.setPrefix('web_');
  return mendel;
};

const mendel = init();

const getVariantByExperimentName = async (name: string, forcedVariant = ''): Promise<string | undefined> => {
  const scope = await getScope();

  const assignedVariant = mendel.getAssignedVariantByExperimentName(name);
  if (assignedVariant) return assignedVariant.variant;

  const experiments = await mendel.getActiveExperiments();
  const experiment = experiments.find(exp => exp.name === name);

  if (experiment) {
    const isRegistered = experiment.scope && experiment.scope.registered && experiment.scope.registered[0] === 'true';
    if (isRegistered) await mendel.deanonymizeProcess();

    const assignedVariant = await mendel
      .getExperimentForUser(experiment.name, mendel.getUserId(), scope, forcedVariant)
      .catch(() => undefined);

    if (assignedVariant) {
      eventManager.emit(ExperimentEvent.EXPERIMENT_APPLIED, {
        eventAction: assignedVariant.variant,
        eventLabel: name,
      });

      return assignedVariant.variant;
    }
  }
  return;
};

const mendelService: MendelService = {
  deanonymize: async userId => {
    await mendel.deanonymize({userId});
    mendel.setUserId(userId);
  },
  getVariantByExperimentName: async ({name, forcedVariant}) => {
    return getVariantByExperimentName(name, forcedVariant);
  },
  getScope: getScope,
};

export {mendelService};
