import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';
import {
  // Helpers
  ALIGN,
  SPACE,
  // Components
  Text,
  View,
} from '@lookiero/aurora';

import {capitalize} from '@helpers';
import {TEXT} from './ShipmentAddress.definition';

export const ShipmentAddress = ({name, summary, ...others}) => {
  const {formatMessage} = useIntl();

  return (
    <View {...others}>
      <Text action align={ALIGN.CENTER} as="p" marginBottom={SPACE.SPACE_4} upperCase>
        {formatMessage(TEXT.TITLE)}
      </Text>
      <View>
        {name && (
          <Text align={ALIGN.CENTER} as="p" level={1}>
            {capitalize(name)}
          </Text>
        )}
        <Text align={ALIGN.CENTER} as="p" level={1}>
          {capitalize(summary)}
        </Text>
      </View>
    </View>
  );
};
ShipmentAddress.propTypes = {
  name: PropTypes.string,
  summary: PropTypes.string.isRequired,
};
