import {EventManager} from '@domain/model/Event/EventManager';
import {ApiEventManager} from '@infra/api/eventManager/ApiEventManager';
import {EventEmit} from './EventEmit';
import {EventSubscription} from './EventSubscription';
import {EventUnsubscription} from './EventUnsubscription';

export const eventManager: EventManager = {
  emit: EventEmit(ApiEventManager),
  subscribe: EventSubscription(ApiEventManager),
  unsubscribe: EventUnsubscription(ApiEventManager),
};
