import {User} from '@domain/model/User/User';

import {executeQuery} from '@infra/api/graphQL/client';
import {Queries} from '@infra/api/graphQL/domain/model/User/queries';
import {Order} from '@domain/model/Order/Order';

interface UserAndOrders {
  user: User;
  ordersV2: Order[];
}

const getUserOverallInfo = async (): Promise<User> => {
  try {
    const data: UserAndOrders = await executeQuery<UserAndOrders>(Queries.USER_SUMMARY_FIELDS);
    return Promise.resolve({
      ...data.user,
      orders: data.ordersV2 || [],
    });
  } catch (e) {
    return Promise.reject(e);
  }
};

export const ApiUserRepository = {
  getUserOverallInfo,
};
