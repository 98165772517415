import gql from 'graphql-tag';

import {ACCOUNT_FIELDS} from '@infra/api/graphQL/domain/model/Account/AccountFields';

const BILLING_ADDRESS_ALL_FIELDS = gql`
  query billingAddressAllFields {
    user {
      billingAddress {
        address {
          ${ACCOUNT_FIELDS.addressFields}
        }
      }
    }
  }
`;

export {BILLING_ADDRESS_ALL_FIELDS};
