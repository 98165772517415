import Cookies from 'js-cookie';

const CookieStorage = {
  set: async (name: string, value: string | object, options?: Cookies.CookieAttributes | undefined): Promise<void> =>
    Cookies.set(name, value, options),
  get: async (name: string): Promise<string | undefined> => Cookies.get(name),
  remove: async (name: string, options?: Cookies.CookieAttributes | undefined): Promise<void> =>
    Cookies.remove(name, options),
};

export {CookieStorage};
