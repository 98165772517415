export type RedirectionTargetType = {pathname: string; queryParams?: string};

export function extractRedirectionTargetFromSearch(search: string): RedirectionTargetType | null {
  const entries = Object.fromEntries(
    (search || '')
      .replace(/^\?/, '')
      .split('&')
      .map(item => item.split('=')),
  );
  const value = entries['redirect'];
  if (!value) return null;
  const [pathname, queryParams] = decodeURIComponent(value).split('?');
  return {pathname, queryParams};
}
