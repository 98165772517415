import {
  DeleteAccountRepository,
  DeleteAccountRepositoryCommand,
} from '@domain/model/Account/DeleteAccount/DeleteAccountRepository';
import {fetch} from '@infra/api/fetch';

export const ApiDeleteAccountRepository: DeleteAccountRepository = {
  deleteAccount: async ({countryCode, email, message, name}: DeleteAccountRepositoryCommand): Promise<void> => {
    try {
      const result = await fetch('/api/contact/', {
        method: 'POST',
        body: JSON.stringify({countryCode, email, message, name}),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      if (!result.ok) {
        const response = await result.json();
        return Promise.reject(response);
      }
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
