export enum PromoType {
  URL = 'URL',
  SITE = 'SITE',
  MULTIBOX = 'MULTIBOX',
  MANUAL = 'MANUAL',
}

export type PromoCode = {
  value: string;
  type: PromoType;
};
