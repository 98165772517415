import {Header} from 'typings/request';
import {AuthStorage} from './authStorage';

const setAuth = async (newToken: string): Promise<void> => AuthStorage.set(newToken);

const getAuth = async (): Promise<string | undefined> => AuthStorage.get();

const removeAuth = async (): Promise<void> => AuthStorage.remove();

const getAuthHeader = async (): Promise<Record<Header, string>> => {
  const token = await getAuth();
  return {authorization: token ? `Bearer ${token}` : ''};
};

export {getAuthHeader, getAuth, setAuth, removeAuth};
