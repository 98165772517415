import {useEffect, useState} from 'react';

const UNMOUNT_DELAY = 300; // Enough time to perform the hide animation (Improve by handling this in Aurora Modal)

type UseMountVisibleProps = {
  isVisible: boolean;
};
type UseMountVisibleReturn = {
  isMountedAndVisible: boolean;
  showComponent: boolean;
};
const useMountablePortal = ({isVisible}: UseMountVisibleProps): UseMountVisibleReturn => {
  const [mountPortal, setMountPortal] = useState(false);
  const [showComponent, setShowComponent] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setMountPortal(true);
    } else {
      setShowComponent(false);
    }
  }, [isVisible]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (isVisible && mountPortal) {
      setShowComponent(true);
    } else if (!isVisible && !showComponent) {
      timeoutId = setTimeout(() => setMountPortal(false), UNMOUNT_DELAY) as unknown as NodeJS.Timeout;
    }
    return () => clearTimeout(timeoutId);
  }, [isVisible, mountPortal, showComponent]);

  const isMountedAndVisible = !(!mountPortal && !showComponent);

  return {isMountedAndVisible, showComponent};
};

export {useMountablePortal};
