import {SPACE} from '@lookiero/aurora';

export const calcMarginRight = ({index, itemsPerRow, numberOfItems}) =>
  itemsPerRow
    ? (index + 1) % itemsPerRow !== 0
      ? SPACE.SPACE_8
      : undefined
    : index + 1 < numberOfItems
      ? SPACE.SPACE_8
      : undefined;
