import {Shipment} from '@domain/model/Shipment/Shipment';
import {ShipmentRepository} from '@domain/model/Shipment/ShipmentRepository';

const GetShipmentByServiceId =
  (shipmentRepository: ShipmentRepository) =>
  (serviceId: string): Promise<Shipment> => {
    return shipmentRepository.getShipmentByServiceId(serviceId);
  };

export {GetShipmentByServiceId};
