const MODALITY = {
  ON_DEMAND: 'on_demand',
  MONTHLY: 'monthly',
  BIMONTHLY: 'bimonthly',
  QUARTERLY: 'quarterly',
} as const;

export type Modality = (typeof MODALITY)[keyof typeof MODALITY];

export interface Subscription {
  id?: string;
  modality: Modality;
  lkDay?: number;
}

const MODALITY_MAPPING = {
  [MODALITY.ON_DEMAND]: 'o',
  [MODALITY.MONTHLY]: 'm',
  [MODALITY.BIMONTHLY]: 'b',
  [MODALITY.QUARTERLY]: 'q',
} as const;

export {MODALITY, MODALITY_MAPPING};
