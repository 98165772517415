import {useMemo} from 'react';
import {useDevice} from '@lookiero/aurora';
import {Country, Locale} from '@domain/model/Site/Locale';
import {Platform} from '@domain/model/App/Platform';
import {useLocale} from './useLocale';

const getAndroidStore = (): string => `https://play.google.com/store/apps/details?id=com.lookiero.uaf.app`;
const getIosStore = (country: Country): string => `https://apps.apple.com/${country}/app/lookiero/id1551933506`;

const getNativeStore = (country: Country, platform: Platform): string | null => {
  if (platform.ios) {
    return getIosStore(country);
  }
  if (platform.android) {
    return getAndroidStore();
  }
  return null;
};

interface StoreResult {
  androidStore: string;
  iosStore: string;
  nativeStore: string | null;
}

export const useStore = (): StoreResult => {
  const {country} = useLocale() as Locale;
  const {platform} = useDevice();

  return useMemo(
    () => ({
      androidStore: getAndroidStore(),
      iosStore: getIosStore(country),
      nativeStore: getNativeStore(country, platform),
    }),
    [country, platform],
  );
};
