import {executeMutation} from '@infra/api/graphQL/client';
import {Mutations} from '@infra/api/graphQL/domain/model/Account/mutations';

const updateEmail = (email: string): Promise<void> => {
  return executeMutation<void>(Mutations.UPDATE_EMAIL, {email});
};

export const ApiUpdateEmailRepository = {
  updateEmail,
};
