import {getConfig} from './_helpers/currencyConfig';

interface FormatCurrencyOpts {
  currency?: string;
}

const formatCurrency = (amount: string, opts: FormatCurrencyOpts = {}): string => {
  const {currency = 'EUR'} = opts;
  const {position, symbol, spacing} = getConfig(currency);
  const formattedCurrencyParams = position === 'after' ? [amount, symbol] : [symbol, amount];
  return formattedCurrencyParams.join(spacing ? ' ' : '');
};

export {formatCurrency};
