import PropTypes from 'prop-types';

export const PROPTYPE_ORDER = PropTypes.shape({
  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string,
      cesionId: PropTypes.string,
      frequency: PropTypes.number,
      status: PropTypes.string,
      nextBoxDate: PropTypes.Date,
      shippingAddress: PropTypes.shape({
        address: PropTypes.shape({
          name: PropTypes.string,
          lastName: PropTypes.string,
          address: PropTypes.string,
          postCode: PropTypes.string,
          city: PropTypes.string,
          phone: PropTypes.string,
        }),
        delivery: PropTypes.shape({
          courier: PropTypes.string,
          pickupId: PropTypes.string,
          pickupName: PropTypes.string,
          permalink: PropTypes.string,
          trackNumber: PropTypes.string,
        }),
      }),
    }),
  ),
});
