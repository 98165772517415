import React, {FC, useMemo} from 'react';
import {
  FLEX_DIRECTION,
  SPACE,
  //Components
  Text,
  View,
  Button,
  AccordionContainer,
  Accordion,
} from '@lookiero/aurora';

import {style} from '../ModalReturnInstructions.styles';
import {TEXT, DEFAULT_COUNTRY} from '../ModalReturnInstructions.definition';
import {useIntl} from 'react-intl';
import {openExternalLink} from '@router/helpers';

interface ModalReturnInstructionsProps {
  links: {[k: string]: string | undefined};
  country: string;
  orderReference?: string;
}

export const ModalReturnInstructionsMR: FC<ModalReturnInstructionsProps> = ({
  links,
  country = DEFAULT_COUNTRY,
  orderReference,
}) => {
  const {formatMessage} = useIntl();

  const renderSpain = useMemo((): JSX.Element => {
    return (
      <>
        <View marginBottom={SPACE.SPACE_4} style={style.steps}>
          <View flexDirection={FLEX_DIRECTION.ROW} key={0} marginBottom={SPACE.SPACE_2}>
            <View style={style.circle}>
              <Text detail>1</Text>
            </View>
            <Text>{formatMessage(orderReference !== undefined ? TEXT.STEPS.ONE : TEXT.SHOP_STEPS.ONE)}</Text>
          </View>
          <View flexDirection={FLEX_DIRECTION.ROW} key={1}>
            <View style={style.circle}>
              <Text detail>2</Text>
            </View>
            <Text>{formatMessage(orderReference !== undefined ? TEXT.STEPS.TWO : TEXT.SHOP_STEPS.TWO)}</Text>
          </View>
        </View>
        <AccordionContainer marginBottom={SPACE.SPACE_8} wide>
          <Accordion key={0} title={formatMessage(TEXT.ACCORDION.SEND_PICKUP.DEFAULT.TITLE)}>
            <View marginVertical={SPACE.SPACE_8}>
              <Text as="p" key={0}>
                {formatMessage(TEXT.ACCORDION.SEND_PICKUP.MR.TEXT)}
              </Text>
            </View>
            <Button onPress={() => openExternalLink({url: links.searchPickupPoint as string})}>
              {formatMessage(TEXT.ACCORDION.SEND_PICKUP.DEFAULT.BUTTON)}
            </Button>
          </Accordion>
          <Accordion key={1} title={formatMessage(TEXT.ACCORDION.SEND_HOME.DEFAULT.TITLE)}>
            <View marginVertical={SPACE.SPACE_8}>
              <Text as="p" key={1}>
                {formatMessage(TEXT.ACCORDION.SEND_HOME.MR.TEXT)}
              </Text>
            </View>
            <Button onPress={() => openExternalLink({url: links.alternativeRequestPickup as string})}>
              {formatMessage(TEXT.ACCORDION.SEND_HOME.DEFAULT.BUTTON)}
            </Button>
          </Accordion>
        </AccordionContainer>
      </>
    );
  }, [formatMessage, links, orderReference]);

  const renderForeignCourier = useMemo((): JSX.Element => {
    return (
      <>
        <View style={style.steps} marginBottom={SPACE.SPACE_4}>
          <View flexDirection={FLEX_DIRECTION.ROW} key={0} marginBottom={SPACE.SPACE_2}>
            <View style={style.circle}>
              <Text detail>1</Text>
            </View>
            <Text>{formatMessage(orderReference !== undefined ? TEXT.STEPS.ONE : TEXT.SHOP_STEPS.ONE)}</Text>
          </View>
          <View flexDirection={FLEX_DIRECTION.ROW} key={1} marginBottom={SPACE.SPACE_2}>
            <View style={style.circle}>
              <Text detail>2</Text>
            </View>
            <Text>{formatMessage(orderReference !== undefined ? TEXT.STEPS.TWO : TEXT.SHOP_STEPS.TWO)}</Text>
          </View>
          {orderReference !== undefined ? (
            <View flexDirection={FLEX_DIRECTION.ROW} key={2}>
              <View style={style.circle}>
                <Text detail>3</Text>
              </View>
              <Text>{formatMessage(TEXT.STEPS.THREE)}</Text>
            </View>
          ) : null}
        </View>
        <Button
          onPress={() => openExternalLink({url: links.searchPickupPoint as string})}
          testID={`find-drop-off-button`}
        >
          {formatMessage(TEXT.RETURN_BUTTON)}
        </Button>
      </>
    );
  }, [formatMessage, links, orderReference]);

  return <>{country === 'ES' ? renderSpain : renderForeignCourier}</>;
};
