import {ShippingAddressCommand} from '@domain/model/Account/Address/domain/model/ShippingAddress/ShippingAddressCommand';

import {executeMutation} from '@infra/api/graphQL/client';
import {Mutations} from '@infra/api/graphQL/domain/model/Account/mutations';

const setShippingAddress = (shippingAddress: ShippingAddressCommand): Promise<Record<string, boolean>> => {
  return executeMutation<Record<string, boolean>>(Mutations.SHIPPING_ADDRESS, {
    ...shippingAddress,
  });
};

export const ApiShippingAddressRepository = {
  setShippingAddress,
};
