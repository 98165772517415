import {PromoCodeRepository as StoragePromoCodeRepository} from '@infra/domain/model/PromoCode/StoragePromoCodeRepository';

import {GetMultiBoxPromoCode} from './GetMultiBoxPromoCode';
import {GetUrlPromoCode} from './GetUrlPromoCode';
import {GetSitePromoCode} from './GetSitePromoCode';
import {GetBoxRequestPromoCode} from './GetBoxRequestPromoCode';
import {ClearBoxRequestPromoCode} from './ClearBoxRequestPromoCode';
import {ClearUrlPromoCode} from './ClearUrlPromoCode';
import {GetPromoBannerFlag} from './GetPromoBannerFlag';
import {SetBoxRequestPromoCode} from './SetBoxRequestPromoCode';
import {SetPromoBannerFlag} from './SetPromoBannerFlag';
import {SetUrlPromoCode} from './SetUrlPromoCode';

export const PromoCodeApplication = {
  getMultiBoxPromoCode: GetMultiBoxPromoCode(),
  getSitePromoCode: GetSitePromoCode(),
  getUrlPromoCode: GetUrlPromoCode(StoragePromoCodeRepository),
  clearUrlPromoCode: ClearUrlPromoCode(StoragePromoCodeRepository),
  getBoxRequestPromoCode: GetBoxRequestPromoCode(StoragePromoCodeRepository),
  setBoxRequestPromoCode: SetBoxRequestPromoCode(StoragePromoCodeRepository),
  clearBoxRequestPromoCode: ClearBoxRequestPromoCode(StoragePromoCodeRepository),
  getPromoBannerFlag: GetPromoBannerFlag(StoragePromoCodeRepository),
  setPromoBannerFlag: SetPromoBannerFlag(StoragePromoCodeRepository),
  setUrlPromoCode: SetUrlPromoCode(StoragePromoCodeRepository),
};
