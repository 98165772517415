import {Platform} from 'react-native';

export enum MenuEntry {
  STYLE = 'STYLE',
  ACCOUNT = 'ACCOUNT',
  SHOP = 'SHOP',
  BLOG = 'BLOG',
  GIFTCARD = 'GIFTCARD',
  HELP = 'HELP',
  TERMS = 'TERMS_AND_CONDS',
}

export interface Link {
  id: string;
  path: string;
  translationKey: string;
  testID: string;
  external: boolean;
}

const HEADLESS_PARAM = Platform.OS === 'web' ? '' : '?headless=true';

export const LINKS: Record<MenuEntry, Link> = {
  [MenuEntry.SHOP]: {
    id: MenuEntry.SHOP,
    path: '/shop/orders',
    translationKey: 'shop.menu.item.my_orders',
    testID: 'link-shop-orders',
    external: false,
  },
  [MenuEntry.STYLE]: {
    id: MenuEntry.STYLE,
    path: '/style-profile',
    translationKey: 'navigation.menu.style',
    testID: 'link-style',
    external: false,
  },
  [MenuEntry.ACCOUNT]: {
    id: MenuEntry.ACCOUNT,
    path: '/account',
    translationKey: 'navigation.menu.account',
    testID: 'link-account',
    external: false,
  },
  [MenuEntry.BLOG]: {
    id: MenuEntry.BLOG,
    path: '/blog',
    translationKey: 'navigation.menu.blog',
    testID: 'link-blog',
    external: true,
  },
  [MenuEntry.GIFTCARD]: {
    id: MenuEntry.GIFTCARD,
    path: `/giftcard/${HEADLESS_PARAM}`,
    translationKey: 'navigation.menu.giftCard',
    testID: 'link-giftcard',
    external: true,
  },
  [MenuEntry.HELP]: {
    id: MenuEntry.HELP,
    path: `/help-and-contact${HEADLESS_PARAM}`,
    translationKey: 'navigation.menu.help',
    testID: 'link-help',
    external: true,
  },
  [MenuEntry.TERMS]: {
    id: MenuEntry.TERMS,
    path: `/terms-and-conds/${HEADLESS_PARAM}`,
    translationKey: 'navigation.menu.tc',
    testID: 'link-terms',
    external: true,
  },
};
