import {DISPLAY, FLEX_DIRECTION, SPACE} from '@lookiero/aurora';

const CONTENT_PROPS = {
  size: {M: '2/3', L: '1/3'},
};

const CONTENT_PROPS_TINY = {
  size: {M: '2/3', L: '1/4'},
};

const TITLE_PROPS = {
  display: DISPLAY.FLEX,
  flexDirection: FLEX_DIRECTION.COLUMN,
  size: {L: '1/2'},
  marginBottom: SPACE.SPACE_12,
};

const APP_STATES = {STRIPE: 'stripe'};

export {APP_STATES, CONTENT_PROPS, CONTENT_PROPS_TINY, TITLE_PROPS};
