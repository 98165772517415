type GetOps = {
  minimumFractionDigits: number;
  maximumFractionDigits: number;
};

const getOps = (amount: number, decimals = false): GetOps => {
  const customFractionDigits = amount % 1 === 0 ? 0 : 2;
  const fractionDigits = decimals ? 2 : customFractionDigits;
  return {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  };
};

type AmountToAbsoluteAndSign = {
  sign: '' | '-' | '+';
  absoluteAmount: number;
};

const amountToAbsoluteAndSign = (amount: number, plus: boolean): AmountToAbsoluteAndSign => ({
  sign: plus ? '+' : Math.sign(amount) >= 0 ? '' : '-',
  absoluteAmount: Math.abs(amount),
});

export {amountToAbsoluteAndSign, getOps};
