import {BillingAddress} from '@domain/model/Account/Address/domain/model/BillingAddress/BillingAddress';
import {BillingAddressCommand} from '@domain/model/Account/Address/domain/model/BillingAddress/BillingAddressCommand';
import {User} from '@domain/model/User/User';

import {executeMutation, executeQuery} from '@infra/api/graphQL/client';
import {Mutations} from '@infra/api/graphQL/domain/model/Account/mutations';
import {Queries} from '@infra/api/graphQL/domain/model/Account/queries';

const getBillingAddress = async (): Promise<BillingAddress | Record<string, never>> => {
  try {
    const data = await executeQuery<Record<string, User>>(Queries.BILLING_ADDRESS_ALL_FIELDS);
    return Promise.resolve(data.user.billingAddress || {});
  } catch (e) {
    return Promise.reject(e);
  }
};

const setBillingAddress = (billingAddress: BillingAddressCommand): Promise<Record<string, boolean>> => {
  return executeMutation<Record<string, boolean>>(Mutations.BILLING_ADDRESS, billingAddress);
};

export const ApiBillingAddressRepository = {
  getBillingAddress,
  setBillingAddress,
};
