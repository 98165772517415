import {StoragePromoCodeRepository} from '@domain/model/PromoCode/StoragePromoCodeRepository';
import {SessionStorage} from '@infra/api/SessionStorage/SessionStorage';

const PROMO_URL_KEY = 'LKPROMO'; // promo key must be aligned with USF
const PROMO_BOX_REQUEST = 'boxrequest-promo';
export const PROMO_BANNER_FLAG = 'hidden_promo_code';

const toUpperCase = (value: string | null): string | null => {
  return (value && value.toUpperCase()) || null;
};

const getPromoUrl = (): string | null => {
  return toUpperCase(SessionStorage.getItem(PROMO_URL_KEY));
};

const removePromoUrl = (): void => {
  SessionStorage.removeItem(PROMO_URL_KEY);
};

const getPromoBannerFlag = (): boolean => {
  return Boolean(SessionStorage.getItem(PROMO_BANNER_FLAG));
};

const setPromoBannerFlag = (value: boolean): void => {
  SessionStorage.setItem(PROMO_BANNER_FLAG, value.toString());
};

const getPromoBoxRequest = (): string | null => {
  return SessionStorage.getItem(PROMO_BOX_REQUEST);
};

const setPromoBoxRequest = (value: string): void => {
  SessionStorage.setItem(PROMO_BOX_REQUEST, value);
};

const clearPromoBoxRequest = (): void => {
  SessionStorage.removeItem(PROMO_BOX_REQUEST);
};

const setPromoUrl = (value: string): void => {
  SessionStorage.setItem(PROMO_URL_KEY, value);
};

const PromoCodeRepository: StoragePromoCodeRepository = {
  getPromoUrl,
  removePromoUrl,
  getPromoBannerFlag,
  setPromoBannerFlag,
  getPromoBoxRequest,
  setPromoBoxRequest,
  clearPromoBoxRequest,
  setPromoUrl,
};

export {PromoCodeRepository};
