import {SiteDTO} from '@domain/model/Site/Site';
import {apiRest} from '@infra/api/rest/api';
import {Locale} from '@domain/model/Site/Locale';

const getMessagesByLocale = (locale: Locale): Promise<Record<string, string> | null> => {
  return apiRest.getMessagesByLocale(locale);
};

const getSettingsByCountry = (country: string): Promise<SiteDTO | null> => {
  return apiRest.getSettingsByCountry(country);
};

export const ApiSiteRepository = {
  getMessagesByLocale,
  getSettingsByCountry,
};
