import {PickupPoint} from '@domain/model/Account/PickupPoint/PickupPoint';
import {PickupPointCommand} from '@domain/model/Account/PickupPoint/PickupPointCommand';

import {executeQuery} from '@infra/api/graphQL/client';
import {Queries} from '@infra/api/graphQL/domain/model/Account/queries';

const getPickupPoints = async (pickupPointCommand: PickupPointCommand): Promise<PickupPoint[] | []> => {
  try {
    const data = await executeQuery<Record<string, PickupPoint[]>>(Queries.PICKUP_POINTS_ALL_FIELDS, {
      ...pickupPointCommand,
    });
    return Promise.resolve(data.pickupsByPostCode || []);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const ApiPickupPointRepository = {
  getPickupPoints,
};
