import React, {useEffect, useState} from 'react';
import {
  // Helpers
  ALIGN,
  DISPLAY,
  FLEX_DIRECTION,
  FLEX_WRAP,
  // Components
  View,
  // Hooks
  styles,
  useDevice,
} from '@lookiero/aurora';

import {ASPECT_RATIO, GalleryPropTypes, MARGIN_ITEM, WIDTH_RATIO_ITEMS_PER_ROW} from './Gallery.definition';
import {style} from './Gallery.style';
import {calcAvailableWidth, calcItemWidth, calcMarginRight} from './helpers';

export const Gallery = ({children = [], fitHeight = true, itemsPerRow, ...others}) => {
  const {screen} = useDevice();

  const [itemWidth, setItemWidth] = useState(undefined);

  const {length: numberOfItems} = children;

  useEffect(() => {
    const nextWidth = calcItemWidth({
      itemsPerRow,
      marginItem: MARGIN_ITEM,
      numberOfItems,
      screen,
    });
    if (nextWidth !== itemWidth) setItemWidth(nextWidth);
  }, [itemsPerRow, numberOfItems, itemWidth, screen]);

  const viewStyle = styles([
    style.gallery,
    itemsPerRow ? {maxWidth: calcAvailableWidth({screen, widthRatio: WIDTH_RATIO_ITEMS_PER_ROW})} : {},
  ]);

  return (
    <View
      {...others}
      alignItems={ALIGN.START}
      display={DISPLAY.FLEX}
      flexDirection={FLEX_DIRECTION.ROW}
      flexWrap={FLEX_WRAP.WRAP}
      justifyContent={screen.S ? ALIGN.BETWEEN : ALIGN.CENTER}
      style={viewStyle}
    >
      {React.Children.map(children, (thumbnail, index) =>
        React.cloneElement(thumbnail, {
          key: index,
          marginRight: screen.L ? calcMarginRight({index, itemsPerRow, numberOfItems}) : undefined,
          marginBottom: thumbnail.props.marginBottom,
          style: {
            marginBottom: !thumbnail.props.marginBottom ? MARGIN_ITEM * 3 : undefined,
            width: itemWidth,
            height: itemWidth && fitHeight ? itemWidth * ASPECT_RATIO : undefined,
          },
        }),
      )}
    </View>
  );
};

Gallery.propTypes = GalleryPropTypes;
