import StyleSheet from 'react-native-extended-stylesheet';
import {IOS_IN_APP_BROWSER_VIDEO_AD_PADDING_BOTTOM} from '@helpers';

const style = StyleSheet.create({
  container: {
    justifyContent: 'space-between',
    display: 'flex',
    flex: 1,
  },
  body: {
    justifyContent: 'space-between',
    display: 'flex',
    flex: 1,
    paddingHorizontal: '$space6',
    paddingTop: '$space6',
    paddingBottom: '$space12',
  },
  mainLink: {
    lineHeight: null,
  },
  content: {
    flex: 1,
  },
  widthTablet: {
    width: '50%',
  },
  widthWeb: {
    width: '25%',
  },
  iOSInAppBrowser: {
    paddingBottom: IOS_IN_APP_BROWSER_VIDEO_AD_PADDING_BOTTOM,
  },
});

export {style};
