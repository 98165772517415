import React, {useContext, useState, useMemo, useEffect} from 'react';

import {SiteApplication} from '@application/Site';
import {useLocale} from '@components/hooks';
import {Site} from '@domain/model/Site/Site';

type SettingsProperties = Site & {loading: boolean};

const settingsInitialValue: Partial<SettingsProperties> = {giftcardAvailable: false, loading: false};

const SettingsContext = React.createContext(settingsInitialValue);

const SettingsProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const {country} = useLocale() as {country: string};

  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState<Partial<Site>>({});

  const getSettingsByCountry = async (country: string): Promise<void> => {
    setLoading(true);

    const settings = await SiteApplication.getSettingsByCountry(country);
    const siteSettings = {
      ...settings,
      postCodeRegex: settings ? new RegExp(settings.postCodeRegex) : undefined,
      phoneRegex: settings ? new RegExp(settings.phoneRegex) : undefined,
    };

    setSettings(prevSettings => (prevSettings.site === siteSettings.site ? prevSettings : siteSettings));
    setLoading(false);
  };

  useEffect(() => {
    if (country) getSettingsByCountry(country);
  }, [country]);

  const value = useMemo(
    () => ({...settings, updateSettingsByCountry: getSettingsByCountry, loading}),
    [loading, settings],
  );

  return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
};

const useSettings = (): Partial<SettingsProperties> => useContext(SettingsContext) || {};

export {useSettings, SettingsProvider, SettingsContext};
