import {calcAvailableWidth} from './calcAvailableWidth';

import {WIDTH_RATIO, WIDTH_RATIO_ITEMS_PER_ROW} from '../Gallery.definition';

// @TODO: In the future we should use variables from theme
const OFFSET = {M: 64, S: 32};

export const calcItemWidth = ({itemsPerRow, marginItem = 0, numberOfItems = 0, screen = {}}) => {
  let itemWidth;

  if (screen.L) {
    const contentWidth =
      calcAvailableWidth({screen, widthRatio: itemsPerRow ? WIDTH_RATIO_ITEMS_PER_ROW : WIDTH_RATIO}) -
      OFFSET.S * ((itemsPerRow || numberOfItems) - 1);
    itemWidth = contentWidth / (itemsPerRow || numberOfItems);
  } else {
    const offset = screen.S ? OFFSET.S : OFFSET.M;
    itemWidth = Math.floor((screen.width - offset * 2) / 2 - marginItem, 10);
  }

  return itemWidth;
};
