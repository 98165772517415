interface CurrencyConfig {
  position: 'before' | 'after';
  symbol: string;
  spacing: boolean;
}

const config: Record<string, CurrencyConfig> = {
  EUR: {
    position: 'after',
    symbol: '€',
    spacing: false,
  },
  GBP: {
    position: 'before',
    symbol: '£',
    spacing: false,
  },
  SEK: {
    position: 'after',
    symbol: 'kr',
    spacing: false,
  },
  CHF: {
    position: 'before',
    symbol: 'CHF',
    spacing: true,
  },
};

const getConfig = (currency: string) => config[currency] || config['EUR'];

export {getConfig};
