import {SiteRepository} from '@domain/model/Site/SiteRepository';
import {Locale} from '@domain/model/Site/Locale';

export const GetMessagesByLocale =
  (siteRepository: SiteRepository): ((locale: Locale) => Promise<Record<string, string> | null>) =>
  (locale: Locale): Promise<Record<string, string> | null> => {
    const {country, language} = locale;

    if (!language || !country) {
      return Promise.resolve(null);
    }

    return siteRepository.getMessagesByLocale(locale);
  };
