import {IGNORED_ERRORS} from './errorFilters';

type SentryException = {
  type: string;
  value: string;
};

type SentryEvent = {
  exception: {
    values: SentryException[];
  };
};

type SentryHint = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  originalException: any;
};

const sanitizeException = (exception: SentryHint['originalException']): Partial<SentryException> => {
  if ('code' in exception && 'message' in exception) {
    return {
      value: `Error ${exception.code}: ${exception.message}`,
    };
  }

  return {};
};

export const errorParser = (event: SentryEvent, hint: SentryHint) => {
  try {
    const {originalException} = hint;

    // objects that are instance of Error can be parsed natively by Sentry
    if (originalException instanceof Error) return event;

    // sanitize custom objects into readable string
    event.exception.values[0] = {
      ...event.exception.values[0],
      ...sanitizeException(originalException),
    };

    // if we use Sentry's beforeSend callback we must filter errors manually
    const errorMessage = event.exception.values[0].value;
    if (IGNORED_ERRORS.find(filter => errorMessage.match(filter))) return null;
  } catch {
    return event;
  }

  return event;
};
