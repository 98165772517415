import React, {FC} from 'react';
import {
  FLEX_DIRECTION,
  SPACE,
  //Components
  Text,
  View,
  Button,
  AccordionContainer,
  Accordion,
} from '@lookiero/aurora';

import {style} from '../ModalReturnInstructions.styles';
import {TEXT, COURIER} from '../ModalReturnInstructions.definition';
import {isCourierReturnEnabled} from '../ModalReturnInstructions.controller';
import {useIntl} from 'react-intl';
import {openExternalLink} from '@router/helpers';
import {Country} from '@domain/model/Site/Locale';

interface ModalReturnInstructionsProps {
  links: {[k: string]: string | undefined};
  country: Country;
  orderReference?: string;
}

export const ModalReturnInstructionsGLS: FC<ModalReturnInstructionsProps> = ({country, links, orderReference}) => {
  const {formatMessage} = useIntl();

  return (
    <>
      <View marginBottom={SPACE.SPACE_8} style={style.steps}>
        <View flexDirection={FLEX_DIRECTION.ROW} key={0} marginBottom={SPACE.SPACE_6}>
          <View style={style.circle}>
            <Text detail>1</Text>
          </View>
          <Text>{formatMessage(orderReference !== undefined ? TEXT.STEPS.ONE : TEXT.SHOP_STEPS.ONE)}</Text>
        </View>
        <View flexDirection={FLEX_DIRECTION.ROW} key={1}>
          <View style={style.circle}>
            <Text detail>2</Text>
          </View>
          <Text>{formatMessage(orderReference !== undefined ? TEXT.STEPS.TWO : TEXT.SHOP_STEPS.TWO)}</Text>
        </View>
      </View>
      <AccordionContainer marginBottom={SPACE.SPACE_8} wide>
        {isCourierReturnEnabled(COURIER.GLS, country) ? (
          <Accordion key={0} title={formatMessage(TEXT.ACCORDION.SEND_PICKUP.GLS.TITLE)}>
            <View marginVertical={SPACE.SPACE_8}>
              <Text as="p" key={0}>
                {formatMessage(TEXT.ACCORDION.SEND_PICKUP.GLS.TEXT)}
              </Text>
            </View>
            <Button onPress={() => openExternalLink({url: links.searchPickupPoint as string})}>
              {formatMessage(TEXT.ACCORDION.SEND_PICKUP.GLS.BUTTON)}
            </Button>
          </Accordion>
        ) : null}
        <Accordion key={1} title={formatMessage(TEXT.ACCORDION.SEND_HOME.GLS.TITLE)}>
          <View marginVertical={SPACE.SPACE_8}>
            <Text as="p" key={0}>
              {formatMessage(TEXT.ACCORDION.SEND_HOME.GLS.TEXT)}
            </Text>
          </View>
          <Button onPress={() => openExternalLink({url: links.requestPickup as string})}>
            {formatMessage(TEXT.ACCORDION.SEND_HOME.GLS.BUTTON)}
          </Button>
        </Accordion>
      </AccordionContainer>
    </>
  );
};
