import gql from 'graphql-tag';

// TODO: REVIEW changeSubscription BBF
const SUBSCRIPTION = gql`
  mutation changeSubscription($modality: String!, $lkDay: Int) {
    changeSubscription(modality: $modality, lkDay: $lkDay)
  }
`;

export {SUBSCRIPTION};
