import StyleSheet from 'react-native-extended-stylesheet';

const style = StyleSheet.create({
  circle: {
    borderWidth: 1,
    width: '$space6',
    height: '$space6',
    borderRadius: '$borderRadiusFull',
    marginRight: '$space2',
    justifyContent: 'center',
    alignItems: 'center',
  },
  steps: {
    width: '100%',
  },
});

export {style};
