import {MODALITY} from '@domain/model/Account/Subscription/Subscription';

const NO_MODALITY = 'no_modality';

const MODALITY_INFO = {
  [MODALITY.ON_DEMAND]: {
    id: 'account.frequency.current_onDemand',
  },
  [MODALITY.MONTHLY]: {
    id: 'account_frequency.explanation1month',
  },
  [MODALITY.BIMONTHLY]: {
    id: 'account_frequency.explanation2month',
  },
  [MODALITY.QUARTERLY]: {
    id: 'account_frequency.explanation3month',
  },
};

const FREQUENCY_SUMMARY = {
  [NO_MODALITY]: {
    id: 'account.menu.frequency_detail_noOrder',
  },
  [MODALITY.ON_DEMAND]: {
    id: 'account.menu.frequency_detail_onDemand',
  },
  [MODALITY.MONTHLY]: {
    id: 'account.menu.frequency_detail_monthly',
  },
  [MODALITY.BIMONTHLY]: {
    id: 'account.menu.frequency_detail_bimonthly',
  },
  [MODALITY.QUARTERLY]: {
    id: 'account.menu.frequency_detail_quarterly',
  },
};

const SUBSCRIPTION_MODALITIES = [
  {
    value: MODALITY.MONTHLY,
    label: {id: 'account_currentLK.subscribing.1month'},
  },
  {
    value: MODALITY.BIMONTHLY,
    label: {id: 'account_currentLK.subscribing.2month'},
    description: {
      id: 'account_currentLK.subscribing.catchPhrase',
    },
  },
  {
    value: MODALITY.QUARTERLY,
    label: {id: 'account_currentLK.subscribing.3month'},
  },
  {
    value: MODALITY.ON_DEMAND,
    label: {id: 'account_currentLK.subscribing.casual'},
  },
];

export {MODALITY, MODALITY_INFO, FREQUENCY_SUMMARY, SUBSCRIPTION_MODALITIES};
