type FieldValidateProps = {
  touched: boolean;
  error: string;
  forceShowErrors?: boolean;
};

const hasFieldError = ({touched, error, forceShowErrors = false}: FieldValidateProps): boolean | undefined =>
  (touched || forceShowErrors) && !!error;

const getFieldHint = ({touched, error, forceShowErrors = false}: FieldValidateProps): string | undefined =>
  hasFieldError({touched, error, forceShowErrors}) ? error : undefined;

export {hasFieldError, getFieldHint};
