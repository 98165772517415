import gql from 'graphql-tag';

const SHIPPING_ADDRESS = gql`
  mutation changeShippingAddress(
    $name: String!
    $lastName: String!
    $postCode: String!
    $address: String!
    $city: String!
    $country: String!
    $phone: String!
    $courier: String
    $pickupId: String
    $pickupName: String
  ) {
    changeShippingAddress(
      shippingAddress: {
        address: {
          name: $name
          lastName: $lastName
          postCode: $postCode
          address: $address
          city: $city
          country: $country
          phone: $phone
        }
        delivery: {courier: $courier, pickupId: $pickupId, pickupName: $pickupName}
      }
    )
  }
`;

export {SHIPPING_ADDRESS};
