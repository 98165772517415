import React, {forwardRef} from 'react';
import {ScrollView as RNScrollView, ScrollViewProps as RNScrollViewProps} from 'react-native';
import {ScrollView as RNGHScrollView, NativeViewGestureHandlerProps} from 'react-native-gesture-handler';
import {IOSInAppBrowserVideoAdScrollView} from './iOSInAppBrowserVideoAdScrollView/IOSInAppBrowserVideoAdScrollView';
import {style} from './ScrollView.style';
import {iOSInAppBrowserVideoAd} from 'ui/helpers';

type ScrollViewProps = RNScrollViewProps & NativeViewGestureHandlerProps;

const ScrollView = forwardRef<RNScrollView, ScrollViewProps>(
  ({children, scrollEnabled, onScroll, contentContainerStyle: customContentContainerStyle, ...props}, ref) => {
    if (iOSInAppBrowserVideoAd) {
      return (
        <IOSInAppBrowserVideoAdScrollView
          ref={ref}
          onScroll={onScroll}
          scrollEnabled={scrollEnabled}
          style={customContentContainerStyle}
        >
          {children}
        </IOSInAppBrowserVideoAdScrollView>
      );
    }

    return (
      <RNGHScrollView
        contentContainerStyle={[style.contentContainerStyle, customContentContainerStyle]}
        onScroll={onScroll}
        showsVerticalScrollIndicator={false}
        scrollEventThrottle={16}
        ref={ref}
        scrollEnabled={scrollEnabled}
        {...props}
      >
        {children}
      </RNGHScrollView>
    );
  },
);

ScrollView.displayName = 'ScrollView';
export {ScrollView};
