import gql from 'graphql-tag';

import {ACCOUNT_FIELDS} from '@infra/api/graphQL/domain/model/Account/AccountFields';

const SHIPPING_ADDRESS_ALL_FIELDS = gql`
  query shippingAddressAllFields {
    user {
      shippingAddress {
        address {
          ${ACCOUNT_FIELDS.addressFields}
        }
        delivery {
          ${ACCOUNT_FIELDS.deliveryFields}
        }
      }
    }
  }
`;

export {SHIPPING_ADDRESS_ALL_FIELDS};
