import React from 'react';
import {ViewStyle} from 'react-native';
import {AnyObject} from 'react-native-extended-stylesheet';
import {View, Touchable, Text, InputOption, COLOR} from '@lookiero/aurora';

import {style} from './OptionSelector.styles';

interface Option {
  readonly id: string;
  readonly title: string;
  readonly label?: string;
  readonly content?: string;
}

interface OptionSelectorProps {
  readonly options: Option[];
  readonly selected?: string;
  readonly onSelected: (id: string) => void;
  readonly hideDescriptionOnBlur?: boolean;
  readonly disableGrayOutOnBlur?: boolean;
  readonly style?: ViewStyle;
}

interface OptionItemProps extends Option {
  readonly hideDescriptionOnBlur: boolean;
  readonly disableGrayOutOnBlur: boolean;
  readonly selected: boolean;
  readonly onPress: (id: string) => void;
  readonly style?: AnyObject | AnyObject[];
}

const OptionItem: React.FC<OptionItemProps> = ({
  id,
  title,
  label,
  content,
  hideDescriptionOnBlur,
  disableGrayOutOnBlur,
  selected,
  onPress,
  style: customStyle = {},
}) => {
  const displayDescription = !(hideDescriptionOnBlur && !selected);
  const isOptionGreyedOut = !disableGrayOutOnBlur && !selected;
  const optionStyle = isOptionGreyedOut ? style.unselectedOption : style.selectedOption;
  const textStyle = isOptionGreyedOut ? style.unselectedText : {};

  return (
    <Touchable style={[style.option, optionStyle, customStyle]} onPress={() => onPress(id)} testID={`option-${id}`}>
      <InputOption style={style.check} name={id} value={id} checked={selected} />
      <View style={{flex: 1}}>
        <View style={style.title}>
          <Text action level={3} style={textStyle}>
            {title}
          </Text>
          {label && (
            <View
              style={style.labelContainer}
              backgroundColor={isOptionGreyedOut ? COLOR.BG_INTERACTIVE_HOVER : COLOR.BG_INTERACTIVE_SELECTED}
            >
              <Text detail level={3} style={[style.label, textStyle]}>
                {label}
              </Text>
            </View>
          )}
        </View>
        {content && displayDescription && (
          <Text detail level={2} color={COLOR.TEXT_MEDIUM} style={[style.description, textStyle]}>
            {content}
          </Text>
        )}
      </View>
    </Touchable>
  );
};

const OptionSelector: React.FC<OptionSelectorProps> = ({
  options,
  selected,
  onSelected,
  hideDescriptionOnBlur = false,
  disableGrayOutOnBlur = false,
  style: customStyle,
}) => {
  return (
    <View style={customStyle}>
      {options.map((option, index) => {
        const isLastItem = index === options.length - 1;
        return (
          <OptionItem
            key={option.id}
            selected={selected === option.id}
            onPress={onSelected}
            hideDescriptionOnBlur={hideDescriptionOnBlur}
            disableGrayOutOnBlur={disableGrayOutOnBlur}
            style={!isLastItem ? style.optionGap : null}
            {...option}
          />
        );
      })}
    </View>
  );
};

export {OptionSelector};
