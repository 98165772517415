import {AuthRepository} from '@domain/model/Auth/AuthRepository';
import {RegisterUserCommand} from '@domain/model/Auth/RegisterUserCommand';

const RegisterUser = async (
  authRepository: AuthRepository,
  createUserCommand: RegisterUserCommand,
): Promise<string | null> => {
  try {
    const {username, password} = createUserCommand;
    if (!username || !password) {
      throw new Error('credentials missing');
    }
    const token = await authRepository.registerUser(createUserCommand);
    return Promise.resolve(token);
  } catch (e) {
    return Promise.reject(e);
  }
};

export {RegisterUser};
