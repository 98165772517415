import gql from 'graphql-tag';

const BILLING_ADDRESS = gql`
  mutation changeBillingAddress(
    $name: String!
    $lastName: String!
    $postCode: String!
    $address: String!
    $city: String!
    $country: String!
    $phone: String!
  ) {
    changeBillingAddress(
      billingAddress: {
        address: {
          name: $name
          lastName: $lastName
          postCode: $postCode
          address: $address
          city: $city
          country: $country
          phone: $phone
        }
      }
    )
  }
`;

export {BILLING_ADDRESS};
