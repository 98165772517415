import {useEffect} from 'react';
import {
  IN_APP_BROWSER_MODAL_PADDING_BOTTOM,
  iOSInAppBrowser,
  iOSInAppBrowserBannerHeight,
  iOSInAppBrowserVideoAd,
  isAndroidInAppBrowser,
} from '@helpers';

interface UseFBInAppBrowser {
  fBModalStyles: {top?: number};
  fBModalContentStyles: {marginBottom?: number; paddingBottom?: number};
  fBModalScrollableContent: boolean;
}

interface UseFBInAppBrowserArgs {
  readonly fullHeightModal: boolean;
  readonly modalWithScrollableContent: boolean;
  readonly isModalVisible: boolean;
}

interface UseFBInAppBrowserFunction {
  (args: UseFBInAppBrowserArgs): UseFBInAppBrowser;
}

const useFBInAppBrowser: UseFBInAppBrowserFunction = ({
  fullHeightModal,
  modalWithScrollableContent,
  isModalVisible,
}) => {
  /* Workaround to fix unexpected scroll behavior with modals fullHeight in FB video Ads in-app browser */
  const applyFullHeightFbFix = iOSInAppBrowserVideoAd && fullHeightModal;
  const modalStyles = applyFullHeightFbFix ? {top: iOSInAppBrowserBannerHeight} : {};
  const fullHeightModalContentStyles = applyFullHeightFbFix ? {marginBottom: iOSInAppBrowserBannerHeight * 3} : {};
  const modalScrollableContent = applyFullHeightFbFix || modalWithScrollableContent;
  /* ***** */

  /* Workaround to fix unexpected padding bottom behavior with modals in FB video Ads in-app browser */
  const modalPaddingBottom =
    iOSInAppBrowser || isAndroidInAppBrowser ? {paddingBottom: IN_APP_BROWSER_MODAL_PADDING_BOTTOM} : {};
  /* ***** */

  useEffect(() => {
    const hasNotBeenScrolled = window.scrollY == 0;
    if (isModalVisible && iOSInAppBrowserVideoAd && hasNotBeenScrolled) {
      window.scroll({top: iOSInAppBrowserBannerHeight, left: 0, behavior: 'smooth'});
    }
  }, [isModalVisible]);

  return {
    fBModalStyles: modalStyles,
    fBModalContentStyles: {...fullHeightModalContentStyles, ...modalPaddingBottom},
    fBModalScrollableContent: modalScrollableContent,
  };
};

export {useFBInAppBrowser};
