import React from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';

import {
  // Helpers
  SIZE,
  SPACE,
  // Components
  OptionContainer,
  OptionItem,
} from '@lookiero/aurora';

import {MODALITY, SUBSCRIPTION_MODALITIES} from '@views/_config/FrequencyModalities';
import {FREQUENCY_IDS} from './FrequencySelector.definition';

const FrequencySelector = ({frequency, onFrequencyChange, modalities = SUBSCRIPTION_MODALITIES, slotText}) => {
  const {formatMessage} = useIntl();
  return (
    <>
      {slotText}

      <OptionContainer
        horizontal={false}
        gutter={SIZE.RESET}
        marginBottom={SPACE.SPACE_12}
        onChange={onFrequencyChange}
        option={frequency}
      >
        {modalities.map(({description, label = {}, value}) => (
          <OptionItem
            detail={description ? formatMessage(description) : undefined}
            key={value}
            name={value}
            outlined
            title={formatMessage(label)}
            // @TODO: Review
            unselected={value !== frequency}
            value={value}
            testID={`frequency-${value}`}
            nativeID={FREQUENCY_IDS[value]}
          />
        ))}
      </OptionContainer>
    </>
  );
};

FrequencySelector.propTypes = {
  frequency: PropTypes.string,
  modalities: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOf([MODALITY.ON_DEMAND, MODALITY.MONTHLY, MODALITY.BIMONTHLY, MODALITY.QUARTERLY]).isRequired,
      label: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
      description: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }),
  ),
  onFrequencyChange: PropTypes.func,
  slotText: PropTypes.node,
};

export {FrequencySelector};
