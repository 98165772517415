const serviceFields = `
  id
  type
  cesionId
  comment
  frequency
  status
  nextBoxDate
  promoCode
  dateCreated`;

const shippingAddressFields = `
  name
  lastName
  address
  postCode
  city
  country
  phone`;

const deliveryFields = `
  courier
  permalink
  trackNumber
  pickupId
  pickupName`;

const collectFields = `
courier
permalink
trackNumber
pickups`;

const feedbackFields = `
name
value`;

const checkoutFields = `
bookingId
comment
items {
  reference
  feedback {
    size {${feedbackFields}}
    style {${feedbackFields}}
    quality {${feedbackFields}}
    price {${feedbackFields}}
    shape {${feedbackFields}}
    comment {${feedbackFields}}
  }
  sizeChange {
    brand
    color
    description
    reference
    size
    title
    id
  }
}
`;

export const ORDER_FIELDS = {checkoutFields, collectFields, deliveryFields, serviceFields, shippingAddressFields};
