import {DomainEvent} from 'typings/domainEvent';
import {EventManager} from '@domain/model/Event/EventManager';

const EventEmit =
  (eventManager: EventManager) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (eventType: DomainEvent, args: any): void => {
    eventManager.emit(eventType, args);
  };

export {EventEmit};
