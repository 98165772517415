import React from 'react';
import PropTypes from 'prop-types';

export const Form = ({onSubmit, ...props}) => (
  <form
    onSubmit={e => {
      e.preventDefault();
      if (onSubmit) onSubmit(e);
    }}
    {...props}
  />
);

Form.propTypes = {
  onSubmit: PropTypes.func,
};
