import React from 'react';
import {Spinner, Overlay} from '@lookiero/aurora';

import {styles} from './OverlaySpinner.styles';

export const OverlaySpinner: React.FC = () => {
  return (
    <Overlay style={styles.overlay} isVisible>
      <Spinner />
    </Overlay>
  );
};
