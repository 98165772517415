import {useEffect} from 'react';

import {useOverallInfo, useSettings} from '@components/_context';

import * as Sentry from '@sentry/browser';

const ErrorReporter = () => {
  const {username = ''} = useOverallInfo();
  const {site = ''} = useSettings();

  useEffect(() => {
    Sentry.setTag('userName', username);
  }, [username]);
  useEffect(() => {
    Sentry.setTag('site', site);
  }, [site]);
  return null;
};

export {ErrorReporter};
